import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { apiUrl } from "../../environment/environment";
import call from "../../videocall/call/calling.mp3";
import useDisconnectMeeting from "./useDisconnectMeeting";
import { useSocket } from "../../context/SocketProvider";

function Calling(props) {
  const navigate = useNavigate();
  const [time, setTime] = useState(0);

  const mid = props.state;
  // let mid = new URLSearchParams(window.location.search).get("mid");
  // let mid = window.location.href.split('id=')[1];
  var loginUser = {};
  const loginuserdetail = sessionStorage.getItem("user");
  const loginuserrole = sessionStorage.getItem("usertype");
  let userType = loginuserrole === "client" ? "trainer" : "client";
  if (loginuserdetail) {
    loginUser = JSON.parse(loginuserdetail);
  }
  const [receiver, setReceiver] = useState(null);
  const [profile, setProfile] = useState(apiUrl + "/img/Small-no-img.png");
  const socket = useSocket()
  // const audio = useRef(new Audio("https://www.soundhelix.com/examples/mp3/SoundHelix-Song-3.mp3"));
  // audio.current.onended = function () {
  //     setPlaying(false);
  // };
  // audio.current.onplay = function () {
  //     setHasError(false);
  // };
  // useEffect(() => {
  //     if (playing) {
  //         audio.current.play().then(() => {
  //             // Audio is playing.
  //         }).catch(error => {
  //             setHasError(true);
  //         });
  //     } else if (!hasError) {
  //         audio.current.pause();
  //     }
  // })

  const [audio] = useState(new Audio(call));

  // const disconnectMeeting = useCallback(async () => {
  //   var obj = {
  //     meetingid: mid,
  //     toid: loginUser?._id,
  //     type: "from",
  //   };
  //   await axios
  //     .post(`${apiUrl}/meeting/disconnectvideosession`, obj, {})
  //     .then(function (response) {
  //       // Stop Sound
  //       // setPlaying(false);

  //       if (response.data.status === 1) {
  //         navigate(-1);
  //       } else {
  //         swal({
  //           title: "Error!",
  //           text: response.data.message,
  //           icon: "error",
  //           button: true,
  //         });
  //       }
  //     })
  //     .catch(function (error) {
  //       //window.alert(error);
  //     });
  // }, [loginUser?._id, mid, navigate]);

  useDisconnectMeeting(
    async () => { socket.emit("callend", { meetingid: mid, toid: loginUser._id, type: "from" }) },
    { delay: 30000 }
  );

  // disconnectMeeting by disconnect button
  const disConnectMeetingButton = () => {
    socket.emit("callend", { meetingid: mid, toid: loginUser._id, })
  }

  // useEffect(() => {
  //   const storedReceiver = sessionStorage.getItem("receiver");
  //   if (storedReceiver) {
  //     const parsedReceiver = JSON.parse(storedReceiver);
  //     setReceiver(parsedReceiver);

  //     const profileUrl = parsedReceiver?.profile
  //       ? apiUrl + parsedReceiver?.profile
  //       : "/img/Small-no-img.png"; // Replace "apiUrl/" with your actual base URL
  //     setProfile(profileUrl);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (mid) {
  //     audio.play();
  //     audio.loop = true;
  //   } else {
  //     audio.pause();
  //     audio.currentTime = 0;
  //   }
  //   return () => {
  //     audio.pause();
  //     audio.currentTime = 0;
  //   };
  // }, [audio, mid]);
  // // Sound Codeing

  // useEffect(() => {
  //   var obj = {
  //     meetingid: mid,
  //   };
  //   axios
  //     .post(`${apiUrl}/meeting/getconnectvideosession`, obj, {})
  //     .then(function (response) {
  //       if (response.data.status === 1) {
  //         // debugger
  //         setTimeout(() => {
  //           setFirstname(
  //             response.data?.result?.receiverData?.firstname);
  //           setProfile(
  //             apiUrl + response.data?.result?.receiverData?.profile ||
  //             "/img/Small-no-img.png"
  //           );
  //         }, 1500);
  //         sessionStorage.setItem("remoteUser", JSON.stringify(response.data?.result?.receiverData))
  //         if (response.data?.result?.videoSessions?.statusid === 1) {
  //           //navigate("/videosession?mid=" + response.data?.result?.videoSessions?.meetingid);
  //           // window.location.href = "/#/videosession?mid=" + response.data?.result?.videoSessions?.meetingid;
  //         } else if (
  //           response.data?.result?.videoSessions?.statusid === 2 ||
  //           response.data?.result?.videoSessions?.statusid === 3
  //         ) {
  //           navigate(-1);
  //         }
  //       }
  //       return true;
  //     })
  //     .catch(function (error) { });
  // }, [mid, navigate, time]);

  return (
    <>
      <html>
        <head>
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <title>Fitness Management</title>
          <link rel="icon" href="/img/favicon.png" />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
          />
          <link
            rel="stylesheet"
            href="/lib/bootstrap/dist/css/bootstrap.min.css"
            asp-append-version="true"
          />
          <link
            rel="stylesheet"
            href="/css/site.css"
            asp-append-version="true"
          />
        </head>
        <body>
          <div className="bg-black">
            <div className="col-md-12">
              <span className="wait-text">
                Please wait while we connect you to the {userType}
              </span>
              <div className="row">
                <div className="vediosession mx-auto d-block my-5 py-5">
                  {profile ?
                    <>
                      <img
                        src={`${profile}`}
                        className="mt-5 w-100"
                        alt="Profile"
                        onError={(e) => {
                          e.target.src = "/img/Small-no-img.png";
                        }}
                      />

                      <h3 className="text-center text-white mt-4">
                        {receiver?.name}
                      </h3>
                    </> :
                    <div >
                      <div className="no-record-box circle-box">
                        <div className="circle-loader">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  }
                  <p className="text-center text-white font-weight-bold">
                    Wants to Join Session
                  </p>
                  <ul className="list-inline d-flex justify-content-between">
                    <a
                      className="dis-connect"
                      href={() => false}
                      onClick={(e) => {
                        e.stopPropagation();
                        disConnectMeetingButton();
                      }}
                    >
                      Disconnect
                    </a>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <script
            src="~/lib/bootstrap/dist/js/bootstrap.bundle.min.js"
            asp-append-version="true"
          ></script>
          <script src="~/js/site.js" asp-append-version="true"></script>
          <script src="~/js/common.js" asp-append-version="true"></script>
        </body>
      </html>
    </>
  );
}

export default Calling;
