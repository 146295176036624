import React, { useEffect, useState } from 'react'
import $ from "jquery";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { apiUrl } from '../../environment/environment';
import axios from 'axios';
import swal from "sweetalert";


function TrainerProfile() {

    const navigate = useNavigate();
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();
    let Id = query.get("Id");

    const ProfileImage_URL = "/img/Small-no-img.png";

    const [isReportResponse, setIsReportResponse] = useState(false);

    const [reportObj, setReportObj] = useState({
        trainerid: Id,
        reason: "",
        isBlock: null,
    });

    const [trainer, setTrainer] = useState({});
    const [currentFlag, setCurrentFlag] = useState("");
    const [profileimagepreview, setProfileImagePreview] = useState(ProfileImage_URL);

    useEffect(() => {
        // callToken();
        fetchProfile();
    }, []);

    const fetchProfile = async () => {
        document.querySelector(".loading").classList.remove("d-none");
        await axios
            .post(`${apiUrl}/trainer/account/getprofilebyid`, { id: Id })
            .then(function (response) {
                console.log("response", response)
                document.querySelector(".loading").classList.add("d-none");
                if (response.data.status === 1) {

                    if (response.data.result) {
                        setTrainer(response.data.result);
                        let profileImage =
                            response.data?.result?.profile !== "null" &&
                                response.data?.result?.profile !== "undefined" &&
                                response.data?.result?.profile !== ""
                                ? apiUrl + response.data?.result?.profile
                                : ProfileImage_URL;
                        setProfileImagePreview(profileImage)
                    }
                }
                return response;
            })
            .catch(function (error) {
                document.querySelector(".loading").classList.add("d-none");
            });
    }

    const inputChange = (e) => {
        console.log(e.target.value);
        setReportObj({ ...reportObj, [e.target.name]: e.target.value })
    }

    const submitReport = async (e) => {
        e.preventDefault()
        reportObj.clientid = Id;
        document.querySelector(".loading").classList.remove("d-none");
        await axios
            .post(`${apiUrl}/client/blockreporttrainer`, reportObj, {})
            .then(function (response) {
                if (response.data.status === 1) {
                    $(".modal-backdrop").hide();
                    swal({
                        title: "Success!",
                        text: response.data.message,
                        icon: "success",
                        button: true,
                    });
                    document.querySelector(".loading").classList.add("d-none");
                    navigate("/mysession");
                } else {
                    swal({
                        title: "Error!",
                        text: response.data.message,
                        icon: "error",
                        button: true,
                    });
                    document.querySelector(".loading").classList.add("d-none");


                }

            })
            .catch(function (error) {
                console.log("error", error);
                document.querySelector(".loading").classList.add("d-none");
            });
        // }
    };

    return (
        <>
            <div className="sub-head-title">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <h1 className="main_title">Trainer Profile</h1>
                        </div>
                        <div className="col-md-6 col-12 text-right">
                            <ul className="list-inline info-icon mb-0">
                                <li className="list-inline-item">
                                    <button data-toggle="modal" data-target="#report-block">
                                        <i className="far fa-flag"></i>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid position-relative">
                <div className="col-12 p-0">
                    <div className="loading d-none height-full">
                        <div className="mainloader"></div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <div className="trainer-main-box client-pro">
                                <div className="myprofile">
                                    <div className="row">
                                        <div className="col-md-12 col-12">
                                            <div className="avatar-upload">
                                                <div className="avatar-preview">
                                                    <div
                                                        id="imagePreview"
                                                        style={{
                                                            backgroundImage: `url(${profileimagepreview})`,
                                                        }}
                                                    ></div>
                                                </div>
                                            </div>
                                            <div className="prof_name">
                                                <h4>
                                                    {`${trainer?.firstname} ${trainer?.lastname}`}
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-12">
                                            <label>Experience</label>
                                            <div className="p_input">
                                                <p>{trainer?.experience || "N/A"}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-12">
                                            <label>Gender</label>
                                            <div className="p_input">
                                                <p>{trainer?.gender || "N/A"}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-12">
                                            <label>Specialitys</label>
                                            <div className="p_input">
                                                <p>{trainer?.specialitys || "N/A"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="trainer-main-box client-pro">
                                <div className="myprofile">
                                    <div className="row">
                                        <div className="col-md-12 col-12 pl-md-2">
                                            <label>About</label>
                                            <div className="p_input">
                                                <p>
                                                    {trainer?.aboutus}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-12">
                                            <label>Trainingstyle</label>
                                            <div className="p_input">
                                                <p>
                                                    {trainer?.trainingstyle || "N/A"}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-12">
                                            <label>Quote</label>
                                            <div className="p_input">
                                                <p>{trainer?.quote || "N/A"}</p>
                                            </div>
                                        </div>

                                        {trainer?.socialmedia?.length > 0 && (
                                            <>
                                                <div className="col-md-12 mt-4">
                                                    <h4 className="font-weight-bold media-titile">Social Media</h4>
                                                    <div className="">
                                                        <ul className="list-inlne social-media-icon py-2 mb-0">
                                                            {trainer?.socialmedia?.map((media, i) => (
                                                                <li key={i} className="list-inline-item position-relative">
                                                                    <div>
                                                                        <Link
                                                                            className="text-decoration-none"
                                                                            to={media.url}
                                                                            target="_blank"
                                                                        >
                                                                            <span className="px-1">
                                                                                <i
                                                                                    className={media.icon}
                                                                                    aria-hidden="true"
                                                                                    title={media.platform}
                                                                                ></i>{" "}
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="report-block" role="dialog">
                <div
                    className="modal-dialog modal-dialog-centered report-trainer"
                    role="document"
                >
                    <div className="modal-content">
                        <button
                            type="button"
                            onClick={() => {
                                $(".modal-backdrop").hide();
                            }}
                            data-dismiss="modal"
                            className="close"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="modal-body">
                            <div className="col-md-12 col-12">
                                <h4 className="book-title text-center mb-4">
                                    Do you want to report or block the client?
                                </h4>
                                <div className="d-sm-flex">
                                    <button
                                        className="training_btn mb-sm-3 mb-md-0 mr-sm-3"
                                        data-toggle="modal"
                                        data-dismiss="modal"
                                        href="#report-resone-block"
                                        onClick={() => {
                                            setIsReportResponse(true);
                                            setReportObj((prevState) => ({
                                                ...prevState,
                                                isBlock: 2,
                                            }));
                                            setCurrentFlag("Report");
                                        }}
                                    >
                                        Report
                                    </button>
                                    <button
                                        className="training_btn"
                                        data-toggle="modal"
                                        data-dismiss="modal"
                                        href="#report-resone-block"
                                        onClick={() => {
                                            setIsReportResponse(true);
                                            setReportObj((prevState) => ({
                                                ...prevState,
                                                isBlock: 1,
                                            }));
                                            setCurrentFlag("Block");
                                        }}
                                    >
                                        Block
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div
                className={`modal fade ${isReportResponse === false ? "" : "show"}`}
                style={{ display: `${isReportResponse === false ? "none" : "block"}` }}
                id="report-resone-block"
                role="dialog"
            >
                <div
                    className="modal-dialog modal-dialog-centered report-trainer"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header ">
                            <div className="d-flex align-items-center ml-3">
                                <img src="/img/common-health.png" alt="logo" />
                                <h5
                                    className="modal-title book-title mb-0 ml-3"
                                    id="staticBackdropLabel"
                                >
                                    {currentFlag}
                                </h5>
                            </div>
                            <button
                                type="button"
                                onClick={() => {
                                    setIsReportResponse(false);
                                    $(".modal-backdrop").hide();
                                }}
                                data-dismiss="modal"
                                className="close"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="col-md-12 col-12">
                                <div className="mb-4">
                                    <textarea
                                        className="w-100 Sessionrej"
                                        placeholder={`Enter Reason ${currentFlag}`}
                                        value={reportObj.reason}
                                        name="reason"
                                        onChange={(e) => inputChange(e)}

                                    ></textarea>
                                </div>
                                <button
                                    className="training_btn"
                                    data-dismiss="modal"
                                    type="submit"
                                    onClick={(e) => submitReport(e)}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default TrainerProfile