import axios from "axios";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { apiUrl } from "../../environment/environment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "../../Pagination/Pagination";

function TrainerPaymentHistory() {
  const navigate = useNavigate()
  const { page } = useParams()

  const [pageNum, setPageNum] = useState(+page);
  const [noOfRecords, setNoOfRecords] = useState(0);
  const limitValue = 10;

  const [trainerPayment, setTrainerPayment] = useState([]);
  const [allTrainerPayment, setAllTrainerPayment] = useState([])
  const [loading, setLoading] = useState(false)

  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are zero-based
  const year = currentDate.getFullYear();

  useEffect(() => {
    GetList(pageNum || 1)
  }, []);


  async function GetList(val) {
    let obje = {
      limitValue: limitValue,
      pageNumber: val || pageNum,
    }
    setLoading(true)
    axios
      .post(`${apiUrl}/payment/gettrainerpayment`, obje)
      .then((response) => {
        // document.querySelector(".loading").classList.add("d-none");
        if (response.status === 200) {
          setTimeout(() => {
            // console.log("trainer payment list",response.data.result[0].totalCount[0].count);
            setNoOfRecords(response?.data?.result[0]?.totalCount[0]?.count || 0)
            setTrainerPayment(response?.data?.result[0]?.paginatedResults.reverse());
            setAllTrainerPayment(response.data?.result2)
            setLoading(false)
          }, 500);
        }
      })
      .catch((err) => {
        setLoading(false)
      });
  }

  const curPage = (pageNum) => {
    // setPageNum(pageNum);
    // GetList(pageNum);
    // navigate(`/paymenthistory/${pageNum}`);
    navigate(`/paymenthistory/${pageNum}`);
    setPageNum(pageNum);
    if (isSearching) {
      setLoading(true);
      setTimeout(async () => {
        await searchClick(searchname, pageNum)
        setLoading(false);

      }, 500);
    } else {
      GetList(pageNum);
    }
  };

  const [isSearching, setSearching] = useState(false);
  const [searchname, setSearchName] = useState("");

  const handlsearchInputs = (e) => {
    setSearchName(e.target.value);
    if (e.target.value !== "") {
      setSearching(true);
      searchClick(e.target.value);
    } else {
      setSearching(false);
      GetList(pageNum);
      setPageNum(pageNum)
    }
  };

  const searchClick = async (searchData, pageNumber) => {
    const obj = {
      limitValue: limitValue,
      pageNumber: pageNumber || pageNum,
    };

    const response = await axios.post(`${apiUrl}/payment/trainerpaymentsearch`, { searchname: searchData, obj }, {})
    if (response.data.status === 1) {
      setTrainerPayment(response?.data?.result?.paginatedResults)
      setNoOfRecords(response?.data?.result?.totalCount[0]?.count || 0)
      setSearching(true);
    }
  }

  const clearClick = async (e) => {
    setSearchName("");
    setSearching(false);
    GetList(pageNum);
  };

  return (
    <>
      <div className="sub-head-title">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-12">
              <h1 className="main_title">Payment Histroy</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="col-md-12 col-12 p-0">
          {loading &&
            <div id="loader" className="loading">
              <div className="mainloader"></div>
            </div>}

          <div className={loading === false && ("trainer-main-box")}>
            <div className="row">
              {/* <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4">
              <div className="input-group position-relative">
                <input className="input-box search-input" type="text" placeholder="Search" onChange={(e) => handlsearchInputs(e)} name="searchinput" value={searchname} autoComplete="off"/>
                {isSearching ? (
                  <>
                    <button className="search_btn mt-0" onClick={clearClick}>
                      <i className="fa fa-times"></i>
                    </button>
                  </>
                ) : (
                  <>
                    <button className="search_btn mt-0" onClick={() => searchClick("")} >
                      <i className="fa fa-search"></i>
                    </button>
                  </>
                )}
              </div>
            </div> */}
              <div className="col-md-4 col-12 mb-4 ml-auto text-right">
                {trainerPayment.length > 0 && (
                  <>
                    <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className="download-table-xls-button btn btn-primary"
                      table="trainer_payment_history_export"
                      filename={`Payment Histroy_${day}/${month}/${year}`}
                      sheet="tablexls"
                      buttonText="Export to excel"
                    />
                  </>
                )}
              </div>
              <div className="col-md-12 col-12">
                {trainerPayment.length > 0 ? (
                  <>

                    <div className="history-table table-responsive">
                      <table className="table" id="trainer_payment_history">
                        <thead>
                          <tr>
                            <th className="wd70">Sr.no</th>
                            {/* <th>profile</th> */}
                            <th className="text-left">Clients name</th>
                            <th className="text-left">Date</th>
                            <th>Status</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {trainerPayment.length > 0 ? (
                            trainerPayment.map((element, index) => {
                              return (
                                <>
                                  <tr id="trainer_payment_history" key={index}>
                                    <td>{index + 1 + (pageNum - 1) * limitValue}</td>
                                    {/* <td className="text-center">
                                      <img
                                        id="trainer_payment_history"
                                        className="history-img"
                                        src={`${apiUrl}${element?.client_data?.profile}`}
                                        onError={(e) => {
                                          e.target.src =
                                            "/img/Small-no-img.png";
                                        }}
                                        alt="img"
                                        style={{ objectFit: "cover" }}
                                      />
                                    </td> */}
                                    <td>
                                      {`${element?.client_data?.firstname} ${element?.client_data?.lastname}`}
                                    </td>
                                    <td>
                                      <span>
                                        <Moment
                                          format="DD MMMM YYYY, hh:mm A"
                                          date={element.createdAt}
                                        />
                                      </span>
                                    </td>
                                    <td className="text-center">
                                      <span
                                        className={`${element.status > 0
                                          ? "text-success font-weight-bold paid-s"
                                          : "text-warning font-weight-bold pro-s"
                                          } p-status font-weight-bold`}
                                      >
                                        {element?.status > 0
                                          ? "Paid"
                                          : "Processing"}
                                      </span>
                                    </td>
                                    <td className="text-center">
                                      <span>
                                        $ {element?.amount?.toString(2)}
                                      </span>
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          ) : (
                            <tr>
                              <td colspan="8" className="text-center">
                                <div className="col-12">
                                  <div className="no-record-box">
                                    <img
                                      src="/img/no-record.png"
                                      alt="no-img"
                                    />
                                    <h4 className="font-weight-bold">
                                      No record found!
                                    </h4>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                          {/* {!trainerPayment ? <tr><td>No Records Found</td></tr> : <></>} */}
                        </tbody>
                      </table>
                    </div>

                  </>
                ) : (loading === false && (<>
                  <div className="col-12">
                    <div className="trainer-main-box">
                      <div className="no-record-box">
                        <img src="/img/no-record.png" alt="no-img" />
                        <h4 className="font-weight-bold">
                          Payment History Empty !
                        </h4>
                        <p>
                          Current you have no any payment transaction yet
                        </p>
                      </div>
                    </div>
                  </div>
                </>)
                )}
              </div>

              {/* Hidden Table for Export */}
              <div style={{ display: "none" }}>
                <table id="trainer_payment_history_export">
                  <thead>
                    <tr>
                      <th className="wd70">Sr.no</th>
                      <th className="text-left">Clients name</th>
                      <th className="text-left">Date</th>
                      <th>Status</th>
                      <th>Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      allTrainerPayment.map((element, index) => {
                        return (
                          <>
                            <tr key={index}>
                              <td>{index + 1 + (pageNum - 1) * limitValue}</td>
                              <td>
                                {`${element?.client_data?.firstname} ${element?.client_data?.lastname}`}
                              </td>
                              <td>
                                <span>
                                  <Moment
                                    format="DD MMMM YYYY, hh:mm A"
                                    date={element.createdAt}
                                  />
                                </span>
                              </td>
                              <td className="text-center">
                                <span
                                  className={`${element.status > 0
                                    ? "text-success font-weight-bold paid-s"
                                    : "text-warning font-weight-bold pro-s"
                                    } p-status font-weight-bold`}
                                >
                                  {element?.status > 0
                                    ? "Paid"
                                    : "Processing"}
                                </span>
                              </td>
                              <td className="text-center">
                                <span>
                                  $ {element?.amount?.toString(2)}
                                </span>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>

              <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                <Pagination
                  className="pagination-bar"
                  currentPage={pageNum}
                  totalCount={noOfRecords}
                  pageSize={limitValue}
                  onPageChange={(page) => curPage(page)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TrainerPaymentHistory;
