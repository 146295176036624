import { useState, useEffect } from "react";

const useDisconnectMeeting = (apiFunction, options = {}) => {
    const {
        delay = 10000,
        onSuccess = () => { }, // Default no-op callback for success
        onFailure = () => { }, // Default no-op callback for failure
    } = options;

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null); // Tracks the status

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(true);
            apiFunction()
                .then((response) => {
                    const { data } = response;

                    // Handle specific statuses
                    if (data?.status === 1) {
                        setData(data);
                        setStatus("Success");
                        setError(null);
                        onSuccess(data); // Call the success callback
                    } else if (data?.status === 2) {
                        const error = new Error("Validation error occurred");
                        setData(null);
                        setStatus("Validation Error");
                        setError(error);
                        onFailure(error); // Call the failure callback
                    } else if (data?.status === 3) {
                        const error = new Error("Internal server error occurred");
                        setData(null);
                        setStatus("Internal Server Error");
                        setError(error);
                        onFailure(error); // Call the failure callback
                    } else {
                        const error = new Error("Unknown response status");
                        setData(null);
                        setStatus("Unknown Status");
                        setError(error);
                        onFailure(error); // Call the failure callback
                    }
                })
                .catch((axiosError) => {
                    // Handle Axios-specific errors
                    let error;
                    if (axiosError.response) {
                        error = new Error(`Server error: ${axiosError.response.statusText}`);
                    } else if (axiosError.request) {
                        error = new Error("No response received from the server");
                    } else {
                        error = new Error(`Error: ${axiosError.message}`);
                    }
                    setData(null);
                    setStatus("Error");
                    setError(error);
                    onFailure(error); // Call the failure callback
                })
                .finally(() => setLoading(false));
        }, delay);

        return () => clearTimeout(timer);
    }, [apiFunction, delay, onSuccess, onFailure]);

    return { data, error, loading, status };
};

export default useDisconnectMeeting;
