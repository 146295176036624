import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl } from "../../environment/environment";
import { Rating } from "react-simple-star-rating";
import swal from "sweetalert";
// import { verifytokenCall } from "../Others/Utils.js";
import ReactReadMoreReadLess from "react-read-more-read-less";
// import { useParams } from "react-router-dom";
function MyRating() {

  // const { page } = useParams();
  // const limitValue = 10;
  // const [pageNum, setPageNum] = useState(+page);
  // const [noOfRecords, setNoOfRecords] = useState(0);

  const [list, setList] = useState({});
  const [isMountRender, setMountRender] = useState(true);
  const [isLoader, setIsLoader] = useState(false);
  useEffect(() => {
    if (isMountRender) return;
  }, [isMountRender]);
  useEffect(() => {
    setMountRender(false);
    GetRatingList();
  }, []);

  async function GetRatingList(val) {
    setIsLoader(true);
    //   let obje = {
    //     limitValue: limitValue,
    //     pageNumber: val || pageNum,
    // }
    await axios
      .post(`${apiUrl}/trainer/trainer/myratings`)
      .then(function (response) {
        if (response.data.status === 1) {
          setTimeout(() => {
            // console.log("response.data.result",response?.data?.result[0].totalCount[0].count);
            setList(response?.data?.result);
            // setNoOfRecords(response?.data?.result[0]?.totalCount[0]?.count || 0)
            setIsLoader(false);
          }, 500);
        } else {
          setIsLoader(false);
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
          //window.alert(response.data.message);
        }
        /* renderArr(response.data.result); */
      })
      .catch(function (error) { });
  }

  //   const curPage = (pageNum) => {
  //     setPageNum(pageNum);
  //     GetRatingList(pageNum);
  //     navigate(`/myratings/${pageNum}`);
  // };

  return (
    <>
      <div className="sub-head-title">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-12">
              <h1 className="main_title">Ratings</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="col-md-12 col-12 p-0">
          <div className="trainer-main-box">
            {isLoader && (
              <div id="loader" className="loading">
                <div className="mainloader"></div>
              </div>
            )}
            <div className="col-lg-12 col-md-12 col-12">
              <div className="row">
                {list?.length > 0 ? (

                  list?.map((ele, index) => {
                    console.log(ele)
                    return (
                      <div className="col-xl-4 col-md-6 col-12 mb-4">
                        <div key={"index" + index} className="rating_box">
                          <div className="spoiler">
                            <div className="d-sm-flex justify-content-sm-between mb-3">
                              <div className="rating-div">
                                <img
                                  src={`${apiUrl}${ele?.client_data?.profile}`}
                                  onError={(e) => {
                                    e.target.src = "/img/Small-no-img.png";
                                  }}
                                  alt="Profile"
                                />
                              </div>
                              <span className="rating-date">
                                {new Date(ele?.date).toLocaleDateString(
                                  "en-IN",
                                  { month: "short", day: "numeric" }
                                )}
                              </span>
                            </div>
                            <div className="rating-content">
                              <p>
                                <ReactReadMoreReadLess
                                  charLimit={150}
                                  readMoreText={"Read more ▼"}
                                  readLessText={"Read less ▲"}
                                  readMoreClassName="read-more-less--more"
                                  readLessClassName="read-more-less--less"
                                >
                                  {ele.sessionrating.review}
                                </ReactReadMoreReadLess>
                              </p>
                              {/* <p>{ele.sessionrating.review.substr(0,150)} {ele.sessionrating.review.length > 150 ? "View more":""}</p> */}
                              {/* <p>{ele.sessionrating.review} {ele.sessionrating.review.length > 150 ? "View more" : ""}</p> */}
                            </div>
                            <p className="text-body mb-0">
                              <span className="font-weight-bold">
                                Session :
                              </span>{" "}
                              Cross-Fit {ele?.client_data?.firstname}
                            </p>
                            <div className="terminal-name">
                              <span className="ft-testimonial-shape"></span>
                              <div className="rating-title">
                                <h5>
                                  {ele?.client_data?.firstname}{" "}
                                  {ele?.client_data?.lastname}
                                </h5>
                                <span>{ele?.sessionrating?.rate / 1}</span>
                                <Rating
                                  initialValue={ele?.sessionrating?.rate}
                                  size="17"
                                  readonly="true"
                                  allowHover="false"
                                  allowHalfIcon="true"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    );
                  })
                ) : (
                  <>
                    {isLoader === false && (
                      <div className="col-12">
                        <div className="trainer-main-box">
                          <div className="no-record-box">
                            <img src="/img/no-record.png" alt="no-img" />
                            <h4 className="font-weight-bold">
                              No Ratings Found
                            </h4>
                            <p>Members can give ratings after completing sessions,
                              <p>You can see the ratings here</p>
                            </p>

                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyRating;
