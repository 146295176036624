import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { apiUrl } from "../../environment/environment";
import Swal from "sweetalert2";
import Calling from "./Calling";
import peer from "../../videocall/Peer";
import { useSocket } from "../../context/SocketProvider";
import ReactPlayer from "react-player";
import WaitingLoader from "../utility/WaitingLoader";
import './VideoSession.css'
import { FaPhone } from "react-icons/fa6";
import { IoMdMicOff } from "react-icons/io";
import { IoMdMic } from "react-icons/io";
import { BsFillCameraVideoOffFill } from "react-icons/bs";
import { BsFillCameraVideoFill } from "react-icons/bs";

// problem in end meeting function reponsible for setting client payment to empty array

function Videosession() {
  const socket = useSocket();
  const [remoteSocketId, setRemoteSocketId] = useState(null);
  const [cameraOff, setCameraOff] = useState(true);
  const [microphoneOff, setMicrophoneOff] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [myStream, setMyStream] = useState();
  const [remoteStream, setRemoteStream] = useState(null);
  const { meetingId } = useParams();
  const { state } = useLocation();


  let mid = meetingId;
  const loginid = JSON.parse(sessionStorage.user)._id;

  const navigate = useNavigate();
  const loginuserrole = sessionStorage.getItem("usertype");

  const [isMountRender, setMountRender] = useState(true);
  const [hasCamera, setHasCamera] = useState(false);
  const [hasMicrophone, setHasMicrophone] = useState(false);

  const remoteUser = JSON.parse(sessionStorage.getItem("remoteUser"))
  const loginUser = JSON.parse(sessionStorage.getItem("user"))
  const loginProfile = loginUser?.profile ? `${apiUrl + loginUser?.profile}` : "/img/Small-no-img.png"
  const remoteProfile = remoteUser?.profile ? `${apiUrl + remoteUser?.profile}` : "/img/Small-no-img.png"

  const checkMediaDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const updatedCamera = devices.find(device => device.kind === 'videoinput');
      const updatedMicrophone = devices.find(device => device.kind === 'audioinput') ? true : false;

      setHasCamera(updatedCamera);
      setCameraOff(updatedCamera)
      setHasMicrophone(updatedMicrophone);
      setMicrophoneOff(updatedMicrophone)
    } catch (error) {
      console.error('Error checking media devices:', error);
    }
  };

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      checkMediaDevices();

      const handleDeviceChange = () => {
        checkMediaDevices();
      };
      navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);

      return () => {
        navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange);
      };
    } else {
      console.error('enumerateDevices not supported.');
    }
  }, [])
  useEffect(() => {
    setMountRender(false);
    peer.createPerrConnection();
  }, []);

  useEffect(() => {
    if (isMountRender) return;
  }, [isMountRender]);

  const endMeeting = useCallback(async () => {
    var obj = {
      meetingid: mid,
      id: loginid,
    };

    if (myStream === null || myStream?.MediaStream?.active === false) {
      return false;
    }

    if (myStream) {
      myStream.getTracks().forEach((track) => {
        track.stop(); // Stop camera and microphone tracks
        setMyStream(null);
      });
      // peer.closePeerConnetion();
      socket.emit("callend", obj);
    }
    await axios
      .post(`${apiUrl}/meeting/endvideosession`, obj, {})
      .then(function (response) {
        var sesId = response.data?.result?.sessionid;
        if (response.data.status === 1) {
          sessionStorage.removeItem("remoteUser")
          sessionStorage.removeItem("receiver")
          navigate("/schedulerequest");
          if (loginuserrole !== "client") {
            // Confirm to fill workout form after VC
            const swalWithBootstrapButtons = Swal.mixin({
              customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
            swalWithBootstrapButtons
              .fire({
                title: "Fill workout form?",
                // text: "You won't be able to revert this!",
                // icon: 'question',
                showCancelButton: true,
                cancelButtonText: "Save for later",
                confirmButtonText: "Complete now",
                reverseButtons: false,
                confirmButtonClass: "mx-2",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  navigate("/sessiondetails?id=" + sesId);
                  // window.location.href = "/sessiondetails?id=" + sesId;
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  navigate("/schedulerequest");
                  // window.location.href = "/schedulerequest";
                }
              });
          } else {

            navigate("/rating?id=" + sesId);
            // window.location.href = "/rating?id=" + sesId;
          }
        } else {
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch(function (error) {
        console.log("call end", JSON.stringify(error));
      });
  }, [loginid, loginuserrole, mid, myStream, navigate, socket]);

  useEffect(() => {
    const handleUserCallEnd = async (data) => {
      // const sessionid = data.sessionid;
      await endMeeting();
      // if (loginuserrole === "client") {
      //   navigate(`/rating?id=${sessionid}`);
      // } else {
      //   navigate(`/sessiondetails?id=${sessionid}`);
      // }
    };
    socket.on("usercallend", handleUserCallEnd);
    return () => {
      socket.off("usercallend", handleUserCallEnd);
    };

  }, [loginuserrole, navigate, socket, endMeeting]);

  useEffect(() => {
    const handleEndMeetingTimeout = async () => {
      await endMeeting();
    };
    // Set timeout to end meeting after 1 hour
    const timeoutId = setTimeout(() => { handleEndMeetingTimeout() }, 1 * 60 * 60 * 1000);
    return () => {
      clearTimeout(timeoutId);
    };

  }, [endMeeting]);


  // const startMeet = () => {
  //     const options = {
  //         roomName: mid,
  //         width: '100%',
  //         height: 500,
  //         configOverwrite: {
  //             prejoinPageEnabled: false,
  //             startWithVideoMuted: 2,
  //             startWithAudioMuted: 2,
  //             startAudioMuted: 0,
  //             startVideoMuted: 0
  //         },
  //         userInfo: {
  //             displayName: (loginUser?.firstname || "Guest")
  //         },
  //         parentNode: document.querySelector('#jitsi-iframe')
  //     }
  //     api = new window.JitsiMeetExternalAPI(domain, options);
  //     api.addEventListeners({
  //         participantJoined: function () {
  //             var plist = api.getParticipantsInfo();
  //         }
  //     });

  //     api.addEventListeners({
  //         participantLeft: function () {
  //             $('#jitsi-iframe').empty();
  //             endMeeting();
  //         }
  //     });
  // }
  // sessionStorage.setItem("mystream",myStream)
  // sessionStorage.setItem("remotestream",remoteStream)

  // const sender = sessionStorage.getItem("mystream")
  // const reciever = sessionStorage.getItem("remotestream")


  const handleUserJoined = useCallback(
    async ({ email, id }) => {
      setRemoteSocketId(id);
      if (id && (hasMicrophone || hasCamera)) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: hasMicrophone,
            video: hasCamera,
          });
          // stream.getTracks().forEach(track=>track.stop());
          setMyStream(stream);
          setIsLoading(false);
          const offer = await peer.getOffer();
          socket.emit("user:call", { to: id, offer });
        } catch (error) {
          console.log(error)
        }

      }
    },
    [socket, hasMicrophone, hasCamera]
  );

  const handleInComingCall = useCallback(
    async ({ from, offer }) => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const camera = devices.find(device => device.kind === 'videoinput') ? true : false;
      const microphone = devices.find(device => device.kind === 'audioinput') ? true : false;
      try {
        if (camera || microphone) {
          setRemoteSocketId(from);
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: microphone,
            video: camera,
          });

          setMyStream(stream);
          setIsLoading(false);

          const ans = await peer.getAnswer(offer);
          socket.emit("call:accepted", { to: from, ans });
        } else {
          console.log("Neither microphone nor camera available.");
        }
      } catch (error) {
        console.log(error);
      }
    },
    [socket]
  );


  const sendStreams = useCallback(() => {
    for (const track of myStream.getTracks()) {
      peer.peer.addTrack(track, myStream);
    }
  }, [myStream]);

  const handleCallAccepted = useCallback(
    ({ from, ans }) => {
      peer.setLocalDescription(ans);
      console.log("Call Accepted!");
      sendStreams();
    },
    [sendStreams]
  );

  const handleNegoNeeded = useCallback(async () => {
    const offer = await peer.getOffer();
    socket.emit("peer:nego:needed", { offer, to: remoteSocketId });
  }, [remoteSocketId, socket]);

  useEffect(() => {
    peer.peer.addEventListener("negotiationneeded", handleNegoNeeded);
    return () => {
      peer.peer.removeEventListener("negotiationneeded", handleNegoNeeded);
    };
  }, [handleNegoNeeded]);

  const handleNegoNeedIncomming = useCallback(
    async ({ from, offer }) => {
      const ans = await peer.getAnswer(offer);
      socket.emit("peer:nego:done", { to: from, ans });
    },
    [socket]
  );

  const handleNegoNeedFinal = useCallback(async ({ ans }) => {
    await peer.setLocalDescription(ans);
  }, []);

  useEffect(() => {
    peer.peer.addEventListener("track", async (ev) => {
      const remoteStream = ev.streams;
      console.log("GOT TRACKS!!");
      setRemoteStream(remoteStream[0]);
    });
  }, []);

  const buttonRef = useRef();

  // useEffect(() => {
  //   if (remoteStream) {
  //     buttonRef.current.click();
  //   }
  // }, [buttonRef, remoteStream]);
  useEffect(() => {
    if (remoteStream && (state === null) && (hasCamera || hasMicrophone)) {
      const timerId = setTimeout(() => {
        sendStreams();
      }, 1000);

      return () => clearTimeout(timerId);
    }
  }, [remoteStream, state, hasCamera, hasMicrophone, sendStreams]);

  useEffect(() => {
    socket.on("user:joined", handleUserJoined);
    socket.on("incomming:call", handleInComingCall);
    socket.on("call:accepted", handleCallAccepted);
    socket.on("peer:nego:needed", handleNegoNeedIncomming);
    socket.on("peer:nego:final", handleNegoNeedFinal);

    return () => {
      socket.off("user:joined", handleUserJoined);
      socket.off("incomming:call", handleInComingCall);
      socket.off("call:accepted", handleCallAccepted);
      socket.off("peer:nego:needed", handleNegoNeedIncomming);
      socket.off("peer:nego:final", handleNegoNeedFinal);
    };
  }, [
    socket,
    handleUserJoined,
    handleInComingCall,
    handleCallAccepted,
    handleNegoNeedIncomming,
    handleNegoNeedFinal,
  ]);

  const remoteStreamVideo = remoteStream?.getTracks().find((track) => track.kind === "video");

  const toggleMicroPhone = async () => {
    try {
      let audioTrack = myStream
        .getTracks()
        .find((track) => track.kind === "audio");
      if (audioTrack) {
        if (audioTrack.enabled) {
          setMicrophoneOff(false);
          audioTrack.enabled = false;
        } else {
          setMicrophoneOff(true);
          audioTrack.enabled = true;
        }
      } else {
        Swal.fire({
          icon: "warning",
          text: "Can't find your microphone",
          confirmButtonText: "Ok",
        })
      }
    } catch (error) {
      console.log("error >", error)
      Swal.fire({
        icon: "warning",
        text: "Can't find your microphone",
        confirmButtonText: "Ok",
      })
    }
  };

  const toggleVideo = async () => {
    try {
      let videoTrack = myStream
        .getTracks()
        .find((track) => track.kind === "video");

      if (videoTrack) {
        if (videoTrack.enabled) {
          setCameraOff(false);
          videoTrack.enabled = false;
        } else {
          setCameraOff(true);
          videoTrack.enabled = true;
        }
      } else {
        Swal.fire({
          icon: "warning",
          text: "Can't find your camera",
          confirmButtonText: "Ok",
        })
      }
    } catch (error) {
      console.log("error >", error)
      Swal.fire({
        icon: "warning",
        text: "Can't find your camera",
        confirmButtonText: "Ok",
      })
    }
  };

  return (

    <>
      {isLoading && remoteSocketId ? (
        <WaitingLoader />
      ) : (
        <>
          {remoteSocketId === null ? (
            <Calling state={meetingId} />
          ) : (
            <div className="container mt-1">
              <div className="row justify-content-center">
                <div className="col-12 position-relative">
                  <div className="video-container">
                    <div
                      className="name-timer"
                      style={{
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                        fontSize: "14px",
                      }}
                    >
                      <p>
                        {remoteUser
                          ? `${remoteUser.firstname} ${remoteUser.lastname}`
                          : "Example Name"}
                        {/* <br />
                        Timer 05:00 */}
                      </p>
                    </div>

                    {/* Remote Stream */}
                    {remoteStream ? (
                      cameraOff ? (
                        <ReactPlayer
                          className="remote-screen"
                          playing
                          url={remoteStream} />
                      ) : (
                        <div
                          className="remoteUser"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <img
                            src={remoteProfile}
                            className="w-25 h-25"
                            alt="Remote User"
                          />
                          <h2>
                            {remoteUser?.firstname} {remoteUser?.lastname}
                          </h2>
                        </div>
                      )
                    ) : (
                      <div
                        className="other-screen"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <h5>Other Screen</h5>
                      </div>
                    )}

                    {/* My Stream */}
                    {myStream && (
                      <div className="my-screen">
                        {cameraOff ? (
                          <ReactPlayer
                            className="mydisplay"
                            muted
                            playing
                            url={myStream}
                          />
                        ) : (
                          <>
                            <img src={loginProfile} alt="" className="img" />
                          </>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="controls">
                    <button
                      onClick={toggleMicroPhone}
                      className="control-icon"
                      style={{
                        backgroundColor: "#ddd",
                        color: "#333"
                      }}
                    >
                      {microphoneOff ? <IoMdMic /> : <IoMdMicOff />}
                    </button>
                    <button
                      onClick={toggleVideo}
                      className="control-icon"
                      style={{
                        backgroundColor: "#ddd",
                        color: "#333",
                      }}
                    >
                      {cameraOff ? (
                        <BsFillCameraVideoFill />
                      ) : (
                        <BsFillCameraVideoOffFill />
                      )}
                    </button>
                    <button
                      className="control-icon end-call"
                      style={{
                        backgroundColor: "#dc3545",
                        color: "white",
                      }}
                      onClick={endMeeting}
                    >
                      <FaPhone />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>

    // <>
    //   <div className="loading d-none">
    //     <div className="mainloader"></div>
    //   </div>

    //   <section className="">
    //     {remoteSocketId === null &&
    //       (state ?
    //         <Calling state={meetingId} />
    //         :
    //         (// incoming before loader (call receiver)
    //           <WaitingLoader />))}

    //     {isLoading && remoteSocketId ? (
    //       //  call sender  loader
    //       <WaitingLoader />
    //     ) : (
    //       <>
    //         {(hasCamera || hasMicrophone) && (
    //           <div>
    //             {myStream && (
    //               <>
    //                 {
    //                   cameraOff ?
    //                     <ReactPlayer className="mydisplay" muted playing url={myStream} />
    //                     : <>
    //                       <ReactPlayer muted hidden playing url={myStream} />
    //                       <div className="mydisplay">
    //                         <img src={loginProfile} alt="" className="w-25 h-25" />
    //                         <h2>You</h2>
    //                       </div>
    //                     </>
    //                 }
    //               </>
    //             )}
    //             {remoteStream && (
    //               <div>
    //                 {remoteStreamVideo ?
    //                   <ReactPlayer
    //                     className="userdisplay"
    //                     playing
    //                     url={remoteStream}
    //                   /> :
    //                   <>
    //                     <ReactPlayer
    //                       hidden
    //                       playing
    //                       url={remoteStream}
    //                     />
    //                     <div className="remoteUser">
    //                       <img src={remoteProfile} className="w-25 h-25" alt="" />
    //                       <h2>{remoteUser?.firstname} {remoteUser?.lastname}</h2>
    //                     </div>
    //                   </>
    //                 }

    //               </div>
    //             )}

    //             {remoteSocketId && <div className="calling-icon">
    //               {/* <button
    //                     className="sendstream"
    //                     hidden
    //                     ref={buttonRef}
    //                     onClick={sendStreams}
    //                   >
    //                     Send Stream
    //                   </button> */}
    //               <button
    //                 className="camera-i mr-2"
    //                 onClick={toggleMicroPhone}
    //               >
    //                 {microphoneOff ? (
    //                   <i className="microphone"></i>
    //                 ) : (
    //                   <>
    //                     <i className="microphone off-microphone"></i>
    //                   </>
    //                 )}
    //               </button>
    //               <button className="camera-i mr-2" onClick={toggleVideo}>
    //                 {cameraOff ? (
    //                   <i className="video-camera"></i>
    //                 ) : (
    //                   <>
    //                     <i className="video-camera off-camera"></i>
    //                   </>
    //                 )}
    //               </button>

    //               <button className="callend" onClick={endMeeting}>
    //                 <i className="phone-end"></i>
    //               </button>
    //             </div>}
    //           </div>
    //         )}
    //       </>
    //     )}
    //     {/* {(!hasCamera && !hasMicrophone) && <div className="trainer-main-box h-100">
    //       <div className="no-record-box">
    //         <h4>No Camera Detected</h4>
    //         <p>Your PC does not have a camera.</p>
    //       </div>
    //     </div>} */}
    //   </section>
    // </>
    //........
    // <div className="container mt-1">
    //   <div className="row justify-content-center">
    //     <div className="col-12 position-relative">
    //       <div className="video-container">
    //         <div className="name-timer" style={{
    //           position: 'absolute',
    //           top: '10px',
    //           left: '10px',
    //           fontSize: '14px'
    //         }}>
    //           <p>{remoteUser ? `${remoteUser.firstname} ${remoteUser.lastname}` : 'Example Name'}<br />Timer 05:00</p>
    //         </div>

    //         {/* Remote Stream */}
    //         {remoteStream ? (
    //           cameraOff ? (
    //             <ReactPlayer
    //               className="remote-screen"
    //               playing
    //               url={remoteStream}
    //               style={{ height: '100%', width: '100%' }}
    //             />
    //           ) : (
    //             <div className="remoteUser" style={{
    //               display: 'flex',
    //               justifyContent: 'center',
    //               alignItems: 'center',
    //               height: '100%'
    //             }}>
    //               <img src={remoteProfile} className="w-25 h-25" alt="Remote User" />
    //               <h2>{remoteUser?.firstname} {remoteUser?.lastname}</h2>
    //             </div>
    //           )
    //         ) : (
    //           <div className="other-screen" style={{
    //             display: 'flex',
    //             justifyContent: 'center',
    //             alignItems: 'center',
    //             height: '100%'
    //           }}>
    //             <h5>Other Screen</h5>
    //           </div>
    //         )}

    //         {/* My Stream */}
    //         {myStream && (
    //           <div className="my-screen">
    //             {cameraOff ? (
    //               <ReactPlayer
    //                 className="mydisplay"
    //                 muted
    //                 playing
    //                 url={myStream}
    //               />
    //             ) : (
    //               <>
    //                 <img src={loginProfile} alt="" className="img" />
    //                 {/* <h2>You</h2> */}
    //               </>
    //             )}
    //           </div>
    //         )}
    //       </div>

    //       <div className="controls ">
    //         <button onClick={toggleMicroPhone} className="control-icon" style={{
    //           backgroundColor: '#ddd',
    //           color: microphoneOff ? '#333' : 'white',
    //         }} >
    //           {
    //             microphoneOff ? <IoMdMicOff /> : <IoMdMic />
    //           }

    //         </button>
    //         <button onClick={toggleVideo} className="control-icon" style={{
    //           backgroundColor: '#ddd',
    //           color: '#333'
    //         }} >
    //           {
    //             cameraOff ? <BsFillCameraVideoFill /> : <BsFillCameraVideoOffFill />
    //           }
    //         </button>
    //         <button className="control-icon end-call" style={{
    //           backgroundColor: '#dc3545',
    //           color: 'white'

    //         }} onClick={endMeeting}>
    //           <FaPhone />
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    ///.................

  );
}

export default Videosession;
