/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Collapse, Modal } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { apiUrl } from "../../environment/environment";
import { getStatusClass } from "../Others/Utils";

let upcoming = [];
function Trainer({ type, serachValue }) {
  const navigate = useNavigate();
  // const [allList, setAllList] = useState([]);

  // const [isLoader, setIsLoader] = useState(false);
  const [dataLoader, setDataLoader] = useState(false);
  const [List, setList] = useState([]);
  const [workoutList, setWorkOutList] = useState([]);
  const [sessionBook, setSessionBook] = useState("");
  const [trainingStyle, setTrainingStyle] = useState("");
  const [status, setStatus] = useState();
  const [open, setOpen] = useState(false);
  const [confirmReqModal, setConfirmReqModal] = useState(false);
  const [startDateStr, setStartDateStr] = useState("");
  const [startTimeStr, setStartTimeStr] = useState("");
  const selectedStartTime = new Date();
  const selectedStartDate = new Date();
  const [pageNum, setPageNum] = useState(1);
  // const [noOfRecords, setNoOfRecords] = useState(0);
  const [requestActive, setRequestActive] = useState("");
  const [upcomingSessions, setUpcomingSessions] = useState([]);
  // const [pathnameUrl, setPathnameUrl] = useState(window.location.pathname);

  // const [remainTime, setRemainTime] = useState([]);
  var noOfRec = 0;
  var actualnoOfRec = 0;
  var isLoaderVal = false;
  var remainTime = [];
  const limitValue = 50;
  /* const queryStringPara = new URLSearchParams(window.location.search);
    let currentStatus = queryStringPara.get("status") || 1; */
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  // FILTER CRITERIAS FROM URL
  let currentStatus = query.get("status");
  /* let trainerType = query.get("type");
    let ratings = query.get("rat");
    let workType = query.get("wtype");
    let gender = query.get("gen"); */

  const [filterObj, setFilterObj] = useState({
    availablestatus: parseInt(currentStatus),
    name: "",
    isfilter: false,
    isStandardTrainers: true,
    ratings: "",
    typeOfWorkout: "",
    gender: "",
    type: "",
    limitValue: limitValue,
    pageNumber: pageNum,
  });
  // const prevScrollY = useRef(0);
  // const [goingUp, setGoingUp] = useState(false);
  // const [clientPayment, setClientPayment] = useState([]);
  // const [planType, setPlanType] = useState('');
  const [userData, setuserData] = useState([]);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var allTrainerList = [];
  // var isFirstLoader = false;

  useEffect(() => {
    document.body.classList.add("scrollHide");
    // var clientPayments = localdata;
    let user = JSON.parse(sessionStorage.getItem("user"));
    setuserData(user);
    // const paymentData = JSON.parse(sessionStorage.getItem("clientPayment"));
    // setClientPayment(paymentData);
  }, []);

  const loadData = async (list, bookmarktrainer, status) => {
    let finalList = [];
    for (var i = 0; i < 3; i++) {
      finalList.push({
        id: i + 1,
        List: [],
        bookmarktrainerList: bookmarktrainer,
      });
    }

    for (var j = 0; j < list.length; j++) {
      for (var k = 0; k < finalList.length; k++) {
        if (j < list.length) finalList[k].List.push(list[j]);
        if (k < finalList.length - 1) j++;
      }
    }

    if (list.length === 0) {
      if (status === 1) {
        const updatedList = (
          <div className="col-12 p-0 mb-4">
            <div className="trainer-main-box">
              <div className="no-record-box">
                <img src="/img/no-record.png" alt="no-img" />
                <h4 className="font-weight-bold">
                  Sorry, No Trainers Available Right Now
                </h4>
                <p>When trainers are available, you can view trainers here</p>
              </div>
            </div>
          </div>
        );

        setList(updatedList);
        // setIsLoader(false);
        setDataLoader(false);
      }
      if (status === 2) {
        const updatedList = (
          <div className="col-12 p-0 mb-4">
            <div className="trainer-main-box">
              <div className="no-record-box">
                <img src="/img/no-record.png" alt="no-img" />
                <h4 className="font-weight-bold">
                  Sorry, No Trainers Online Right Now
                </h4>
                <p>
                  When the trainers are busy with another session, the trainer
                  can be seen here
                </p>
              </div>
            </div>
          </div>
        );
        setList(updatedList);
        setDataLoader(false);
        // setIsLoader(false);
      }
      if (status === 0) {
        const updatedList = (
          <div className="col-12 p-0 mb-4">
            <div className="trainer-main-box">
              <div className="no-record-box">
                <img src="/img/no-record.png" alt="no-img" />
                <h4 className="font-weight-bold">
                  Sorry, There Are Currently No Trainers
                </h4>
                <p>
                  The trainer is not available, the trainer joins and then you
                  can see here
                </p>
              </div>
            </div>
          </div>
        );
        setList(updatedList);
        setDataLoader(false);
        // setIsLoader(false);
      }
    } else {
      const updatedList = finalList.map((listitem, index) => {
        return (
          <>
            <div key={"mainkey" + index} className="col-xl-4 col-md-6 col-12 ">
              <div className="wrap" style={{ height: "95%", overflow: "auto" }}>
                <div
                  className="frame smart"
                  onScroll={onScrollDown}
                  id={"smart" + index}
                  style={{
                    overflow: "auto",
                    height: "500px",
                    scrollbarWidth: "none",
                  }}
                >
                  <ul className="items">
                    {listitem.List.filter(
                      (tainerlist) =>
                        tainerlist.availablestatus === status || status === 0
                    ).map((tainerlist, sindex) => {
                      return (
                        <li key={"subkey" + sindex} className="col-12">
                          <div
                            className="image-box"
                            title={tainerlist.firstname}
                          >
                            <div className="banner-img">
                              <img
                                src={`${apiUrl + tainerlist.profile}`}
                                onError={(e) => {
                                  e.target.src = "/img/Back-No-Image.png";
                                }}
                                alt=""
                              />
                              <Link
                                to={"/trainerinformation?Id=" + tainerlist._id}
                                className="img-content"
                              >
                                <div className="banner-i d-flex justify-content-between">
                                  <span>{tainerlist.type || ""}</span>
                                  <button
                                    className="bookmark"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      bookmarkTainer(tainerlist, status);
                                    }}
                                  >
                                    <i
                                      className={`${listitem.bookmarktrainerList.filter(
                                        (f) => f === tainerlist._id
                                      ).length > 0
                                        ? "fa"
                                        : "far"
                                        } fa-bookmark`}
                                    ></i>
                                  </button>
                                </div>
                              </Link>
                            </div>
                            <div className="banner-user">
                              <div className="d-sm-flex justify-content-between items-center">
                                <div className="d-flex items-center">
                                  <div
                                    // to={"/trainerinformation?Id=" + tainerlist?._id}
                                    title={tainerlist?.firstname}
                                    className="user-name"
                                    onClick={"/mysession"}
                                  >
                                    <div className="">
                                      <span>{tainerlist?.firstname}</span>
                                      <i
                                        className={getStatusClass(
                                          tainerlist?.availablestatus
                                        )}
                                      ></i>

                                      <Rating
                                        initialValue={
                                          tainerlist?.averageRating * 20
                                        }
                                        size="20"
                                        readonly="true"
                                        allowHover="false"
                                        allowHalfIcon="true"
                                      />
                                      <p className="mb-0 trainer-disc">
                                        {tainerlist?.trainingstyle !== "" &&
                                          tainerlist?.trainingstyle ? (
                                          <span>
                                            {tainerlist?.trainingstyle}
                                          </span>
                                        ) : (
                                          <></>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  {status !== 1 ? (
                                    <>
                                      <Link
                                        to={`/mysession`}
                                        className="banner-btn shadow"
                                      >
                                        Start Training
                                      </Link>
                                    </>
                                  ) : (
                                    <>
                                      <Link
                                        to="/trainer"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          postSendRequest(tainerlist);
                                        }}
                                        className={`${requestActive} banner-btn shadow`}
                                      >
                                        Start
                                      </Link>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="">
                                {status === 2 ? (
                                  <>
                                    {remainTime.length > 0
                                      ? remainTime.map((element) => {
                                        return (
                                          <>
                                            {element.remainingTime !== "" ? (
                                              <span className="btn btn-light btn-sm shadow m-0 p-0 w-100 mt-2 mr-2">
                                                {(
                                                  60 -
                                                  (moment
                                                    .duration(
                                                      moment(new Date())
                                                        .utc()
                                                        .diff(
                                                          element.remainingTime
                                                        )
                                                    )
                                                    .asMinutes() %
                                                    60)
                                                ).toFixed()}
                                                <span> mins left</span>
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        );
                                      })
                                      : ""}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                              {status !== 2 && upcomingSessions.length > 0 ? (
                                upcomingSessions.map((elem, index) => {
                                  if (elem.trainerid === tainerlist._id) {
                                    let startHour = moment(
                                      elem.startdatetime
                                    ).format("DD-MM-YYYY hh:mm A");
                                    let minutes = moment(elem.startdatetime)
                                      .add(-15, "minutes")
                                      .format("DD-MM-YYYY hh:mm A");
                                    let current = moment(new Date()).format(
                                      "DD-MM-YYYY hh:mm A"
                                    );
                                    return (
                                      <>
                                        {minutes <= current &&
                                          startHour > current ? (
                                          <span
                                            key={index}
                                            className="btn btn-sm btn-light p-1 mt-2 px-2"
                                            style={{
                                              fontSize: "11px",
                                              borderRadius: "10px",
                                              color: "#243881",
                                            }}
                                          >
                                            <i className="fa fa-hourglass-half"></i>
                                            15 mins left
                                          </span>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    );
                                  }
                                  return <></>;
                                })
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </>
        );
      });
      setDataLoader(false);
      // setIsLoader(false);
      setList(updatedList);
    }
  };

  const GetList = useCallback(
    async (status, val) => {
      filterObj.isfilter = false;
      var trainerfilterObj = filterObj;
      trainerfilterObj.availablestatus = status;
      trainerfilterObj.pageNumber = val;
      trainerfilterObj.name = serachValue;
      setDataLoader(true);
      // setIsLoader(true);
      if (serachValue) {
        setDataLoader(false);
        // setIsLoader(false);
        trainerfilterObj.isfilter = serachValue ? true : false;
        trainerfilterObj.name = serachValue;
      }

      setStatus(status);

      await axios
        .post(`${apiUrl}/trainer/trainer/maintrainerlist`, trainerfilterObj)
        .then(function (response) {
          if (response.data.status === 1) {
            setTimeout(() => {
              let reamin = response.data?.result?.trainerlist.filter(
                (x) => x.availablestatus === 2
              );
              // setRemainTime(reamin);
              remainTime = [];
              remainTime.push(reamin);
              noOfRec = response.data?.result?.noOfRecords || 0;

              var tempList = [];
              if (
                allTrainerList === null ||
                allTrainerList === undefined ||
                allTrainerList.length <= 0
              ) {
                tempList = response.data?.result?.trainerlist;
              } else {
                if (noOfRec >= allTrainerList.length) {
                  // tempList = allTrainerList;
                  for (
                    let index = 0;
                    index < response.data?.result?.trainerlist.length;
                    index++
                  ) {
                    tempList.push(response.data?.result?.trainerlist[index]);
                  }
                }
              }
              actualnoOfRec = tempList.length || 0;
              allTrainerList = tempList;
              remainTime = allTrainerList;
              // setAllList(tempList);
              loadData(
                tempList,
                response.data?.result?.client_data?.bookmarktrainer,
                status
              );
              setDataLoader(false);
            }, 500);
          } else {
            setDataLoader(false);
            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              button: true,
            });
          }
          isLoaderVal = false;
        })
        .catch(function (error) {
          console.log(error, "error");
          setDataLoader(false);
          // setIsLoader(false);
        });
    },
    [serachValue]
  );

  const bookmarkTainer = async (e, status) => {
    const formData = new FormData();
    formData.append("tainerId", e._id);
    setDataLoader(true);
    await axios
      .post(`${apiUrl}/client/bookmarktrainer`, formData, {})
      .then(function (response) {
        if (response.data.status === 1) {
          setTimeout(() => {
            GetList(status, 1);
            swal({
              title: "Success!",
              text: response.data.message,
              icon: "success",
              button: "ok",
            });
            setDataLoader(false);
          }, 300);
        } else {
          setDataLoader(false);
          // setIsLoader(false);
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch(function (error) {
        setDataLoader(false);
        // setIsLoader(false);
      });
  };

  const onScrollDown = () => {
    if (isLoaderVal === true || noOfRec <= actualnoOfRec) {
      return;
    } else {
      // setIsLoader(true);
      setDataLoader(true);
      isLoaderVal = true;
      const queryStringPara = new URLSearchParams(window.location.search);
      let currentStatus = queryStringPara.get("status") || 1;
      var newpageNum = pageNum;

      GetList(parseInt(currentStatus), newpageNum + 1);
      GetList(parseInt(currentStatus), newpageNum);
      setDataLoader(false);
      setPageNum(newpageNum);
    }
  };

  async function getTypeOfWorkout() {
    // setIsLoader(true);
    setDataLoader(true);
    await axios
      .get(`${apiUrl}/trainer/trainer/getworkoutcategory`, {}, {})
      .then(function (response) {
        if (response.data.status === 1) {
          setWorkOutList(response.data.result);
          // setDataLoader(false)
        } else {
          setDataLoader(false);
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch(function (error) {
        setDataLoader(false);
        // setIsLoader(false);
        window.alert(error);
      });
  }

  const onSubmitFilter = async (objName, val) => {
    filterObj.isfilter = true;

    // navigate(`/trainer?status=1/?type=${val}`);
    // return false;
    // navigate(`?status=${currentStatus}&&${objName}=${val}`);
    var trainerfilterObj = filterObj;
    trainerfilterObj.availablestatus = parseInt(currentStatus);
    if (val) {
      // trainerfilterObj[objName] = val;
      setFilterObj((prevState) => ({ ...prevState, [objName]: val }));
    }

    trainerfilterObj[objName] = val;
    // setIsLoader(true);
    setDataLoader(true);
    await axios
      .post(`${apiUrl}/trainer/trainer/maintrainerlist`, trainerfilterObj)
      .then(function (response) {
        if (response.data.status === 1) {
          setTimeout(() => {
            noOfRec = response.data?.result?.noOfRecords || 0;

            var tempList = [];
            if (allTrainerList.length <= 0) {
              tempList = response.data?.result?.trainerlist;
            } else {
              tempList = allTrainerList;
              for (
                let index = 0;
                index < response.data?.result?.trainerlist.length;
                index++
              ) {
                tempList.push(response.data?.result?.trainerlist[index]);
              }
            }
            // setAllList(tempList);
            loadData(
              tempList,
              response.data?.result?.client_data?.bookmarktrainer,
              status
            );
            setDataLoader(false);
          }, 500);
        } else {
          setDataLoader(false);
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch(function (error) {
        setDataLoader(false);
        // setIsLoader(false);
      });
  };

  const postSendRequest = async (trainer_data) => {
    let isSubmit = true;

    const paymentData = JSON.parse(sessionStorage.getItem("clientPayment"));
    // if (!paymentData || paymentData?.length === 0) {
    //   // alert("paymentData not found");
    //   // swal({
    //   //   // title: "info",
    //   //   text: "PaymentData Not Found",
    //   //   icon: "info",
    //   //   button: true,
    //   // });
    //   return;
    // }

    if (trainer_data.type !== undefined) {
      const isFound = paymentData.some((element) => {
        if (
          element.plantype.toLowerCase() === trainer_data.type.toLowerCase()
        ) {
          return true;
        }
        return false;
      });
      if (!isFound) {
        isSubmit = false;

        Swal.fire({
          icon: "warning",
          text: `Please purchase ${trainer_data?.type.toLowerCase()} session`,
          showCancelButton: true,
          confirmButtonText: "Purchase",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/purchasesession", { state: trainer_data?.type });
          }
        });
      }
    } else {
      isSubmit = false;

      Swal.fire({
        icon: "warning",
        text: "Please purchase session",
        showCancelButton: true,
        confirmButtonText: "Purchase",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/purchasesession");
        }
      });
    }
    if (isSubmit) {
      var sTime = selectedStartTime;
      var endTime = new Date(selectedStartTime);
      endTime = new Date(endTime.setMinutes(endTime.getMinutes() + 60));

      var ssdate = new Date(selectedStartDate);
      ssdate.setHours(sTime.getHours());
      ssdate.setMinutes(sTime.getMinutes());

      var endate = new Date(selectedStartDate);
      endate.setHours(endTime.getHours());
      endate.setMinutes(endTime.getMinutes());
      let obj = {
        trainerid: trainer_data._id,
        date: selectedStartDate,
        starthour: formatDate(sTime),
        endhour: formatDate(endTime),
        startdatetime: ssdate,
        enddatetime: endate,
        requestType: 1,
      };

      setStartDateStr(
        selectedStartDate.getDate() +
        " " +
        monthNames[selectedStartDate.getMonth()]
      );
      setStartTimeStr(
        sTime.getHours() +
        ":" +
        sTime.getMinutes() +
        " - " +
        endTime.getHours() +
        ":" +
        endTime.getMinutes()
      );
      setSessionBook(trainer_data?.firstname);
      setTrainingStyle(trainer_data?.trainingstyle.substr(0, 10));

      // setDataLoader(true)
      // setIsLoader(true);
      await axios
        .post(`${apiUrl}/client/session/sessionrequest`, obj, {})
        .then(function (response) {
          // setIsLoader(false);
          if (response.data.status === 1) {
            setRequestActive("disabled");
            setConfirmReqModal(true);
            // setDataLoader(false)
          } else if (response.data.status === 2 && response.data.trainerType) {
            setDataLoader(false);
            Swal.fire({
              icon: "warning",
              text: response.data.message,
              confirmButtonText: "Purchase",
              showCancelButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/purchasesession", {
                  state: response.data.trainerType,
                });
              }
            });
          } else if (response.data.status === 3) {
            setDataLoader(false);
            swal({
              title: response.data.message,
              text: response.data.message1,
              icon: "error",
              button: true,
            });
          } else {
            setDataLoader(false);
            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              button: true,
            });
          }
        })
        .catch(function (error) {
          setDataLoader(false);
          // setIsLoader(false);
        });
    }
  };

  function formatDate(idate) {
    var d = new Date(idate);
    var hh = d.getHours();
    var m = d.getMinutes();
    var s = d.getSeconds();
    var dd = "AM";
    var h = hh;
    if (h >= 12) {
      h = hh - 12;
      dd = "PM";
    }
    if (h === 0) {
      h = 12;
    }

    m = m < 10 ? "0" + m : m;
    s = s < 10 ? "0" + s : s;
    h = h < 10 ? "0" + h : h;

    return h + ":" + m + ":" + s + " " + dd;
  }
  const clearObj = () => {
    const queryStringPara = new URLSearchParams(window.location.search);
    let currentStatus = queryStringPara.get("status") || 1;
    setFilterObj({
      ...filterObj,
      availablestatus: parseInt(currentStatus),
      isfilter: false,
      isStandardTrainers: true,
      ratings: "",
      typeOfWorkout: "",
      gender: "",
      type: "",
    });
    GetList(parseInt(currentStatus), 1);
  };

  const getSessions = useCallback(async () => {
    await axios
      .get(`${apiUrl}/client/session/getclientsession`, { id: userData._id })
      .then((response) => {
        if (response.status === 200) {
          // upcomingSessions = response.data.result.upcomingList;
          upcoming = response.data.result.upcomingList;
          setUpcomingSessions(upcoming);
        }
      })
      .catch((err) => { });
  }, [userData?._id]);

  useEffect(() => {
    /*  if(isFirstLoader ==  false){
             navigate("/trainer");
             isFirstLoader = true;
         } */
    getSessions();
    /* let trainerType = clientPayment.filter(x => x.plantype == 'Elite') */
    // callToken();
    getTypeOfWorkout();
    GetList(status || parseInt(currentStatus), 1);
  }, [GetList, currentStatus, getSessions, status]);

  const filterClear = () => {
    setFilterObj({
      availablestatus: parseInt(currentStatus),
      name: "",
      isfilter: false,
      isStandardTrainers: true,
      ratings: "",
      typeOfWorkout: "",
      gender: "",
      type: "",
      limitValue: limitValue,
      pageNumber: pageNum,
    });
  };
  return (
    <>
      <div className="sub-head-title">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2 col-12">
              <h1 className="main_title">Trainers</h1>
            </div>
            <div className="col-md-10 col-12">
              <ul className="filter_nav list-inline">
                <li
                  className={`list-inline-item ${status === 1 ? "active" : ""}`}
                  onClick={() => {
                    GetList(1, 1);
                    navigate("/trainer?status=1");
                    filterClear();
                  }}
                >
                  <button>Available Trainers</button>
                </li>
                <li
                  className={`list-inline-item ${status === 2 ? "active" : ""}`}
                  onClick={() => {
                    GetList(2, 1);
                    navigate("/trainer?status=2");
                    filterClear();
                  }}
                >
                  <button>Online Trainers</button>
                </li>
                <li
                  className={`list-inline-item ${status === 0 ? "active" : ""}`}
                  onClick={() => {
                    GetList(0, 1);
                    navigate("/trainer?status=0");
                    filterClear();
                  }}
                >
                  <button>All Trainers</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Collapse in={open}>
        <div id="session-book">
          <div className="col-md-6 col-12 mx-auto">
            <div>
              <div className="session-book">
                <div className="row">
                  <div
                    className="col-md-2 col-12 text-center"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <i className="far fa-check-circle check-s"></i>
                  </div>
                  <div className="col-md-10 col-12">
                    <div className="row">
                      <div className="col-12">
                        <span className="float-md-left">Session Booked!</span>
                        <span className="float-md-right">{startDateStr}</span>
                      </div>
                      <div className="col-12 session-text">
                        <span className="float-md-left">
                          {trainingStyle} with {sessionBook}
                        </span>
                        <span className="float-md-right">{startTimeStr}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Collapse>

      <div className="container-fluid">
        <div className="col-12 p-0">
          {dataLoader && (
            <div id="loadermodal" className="loader">
              <div className="popuploader loginloader"></div>
            </div>
          )}

          <div className="trainer-main-box">
            {status === 1 ? (
              <>
                <h4 className="main_title session-sub-title">
                  {" "}
                  Available Trainers{" "}
                </h4>
              </>
            ) : status === 2 ? (
              <>
                <h4 className="main_title session-sub-title">
                  {" "}
                  Online Trainers{" "}
                </h4>
              </>
            ) : (
              <>
                <h4 className="main_title session-sub-title"> All Trainers </h4>
              </>
            )}
            {type === "openFilter" && (
              <div>
                <div className="row filter-box">
                  <div className="col-md-5 col-12 mb-3">
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        id="customRadioInline1"
                        name="customRadioInline1"
                        className="custom-control-input"
                        checked={filterObj.type === "Standard" ? true : false}
                        onChange={(e) => {
                          onSubmitFilter(
                            "type",
                            e.currentTarget.checked === true
                              ? "Standard"
                              : "ELite"
                          );
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customRadioInline1"
                      >
                        Standard Trainers
                      </label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        id="customRadioInline2"
                        name="customRadioInline1"
                        className="custom-control-input"
                        checked={filterObj.type === "ELite" ? true : false}
                        onChange={(e) => {
                          onSubmitFilter(
                            "type",
                            e.currentTarget.checked === true
                              ? "ELite"
                              : "Standard"
                          );
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customRadioInline2"
                      >
                        Elite Trainers
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row filter-input mb-3">
                      <div className="col-md-3 col-12">
                        <div className="position-relative">
                          <label>Ratings</label>
                          <select
                            className="input-box"
                            value={filterObj.ratings}
                            onChange={(e) => {
                              onSubmitFilter("ratings", e.target.value);
                            }}
                          >
                            <option value={""}>Select order</option>
                            <option value={1}>Ascending</option>
                            <option value={-1}>Descending </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3 col-12">
                        <div className="position-relative">
                          <label>Type Of Workout</label>
                          <select
                            className="input-box"
                            value={filterObj.typeOfWorkout}
                            onChange={(e) => {
                              onSubmitFilter("typeOfWorkout", e.target.value);
                            }}
                          >
                            <option value={""}>Choose Workout</option>
                            {workoutList.map(({ _id, name }, index1) => (
                              <option
                                key={"wprkoptionkey" + index1}
                                value={name}
                              >
                                {name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3 col-12">
                        <div className="position-relative">
                          <label>Gender</label>
                          <select
                            className="input-box"
                            value={filterObj.gender}
                            onChange={(e) => {
                              onSubmitFilter("gender", e.target.value);
                            }}
                          >
                            <option value={""}>Select gender</option>
                            <option value={"Male"}>Male</option>
                            <option value={"Female"}>Female</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3 col-12 d-none">
                        <div
                          className="training_btn mt-4"
                          onClick={() => {
                            clearObj();
                          }}
                        >
                          Cancel
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="row">{List}</div>
          </div>
        </div>
      </div>

      <Modal
        show={confirmReqModal}
        onHide={(e) => {
          setConfirmReqModal(false);
        }}
        className="mbody sessiondetail"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          className="bg-transparent text-dark border-0 session-m"
          closeButton
        >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-2 pb-5">
          <div
            className="col-md-12 col-12 text-center"
            onClick={() => {
              setConfirmReqModal(false);
              setTimeout(() => {
                setOpen(false);
              }, 5000);
            }}
          >
            <button
              className="checkbtn"
              onClick={() => setOpen(true)}
              aria-controls="session-book"
              data-dismiss="modal"
              aria-expanded={open}
            >
              <i className="far fa-check-circle check-i"></i>
              <h4 className="book-title">
                Awaiting confirmation from Trainer.
              </h4>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Trainer;
