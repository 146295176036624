/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { apiUrl } from "../../environment/environment";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import swal from "sweetalert";
import { Link, useNavigate, useParams } from 'react-router-dom'
import Pagination from "../../Pagination/Pagination";

function ClientBlock() {
  const navigate = useNavigate()
  const { page } = useParams()
  console.log("page", page);

  const [pageNum, setPageNum] = useState(+page);
  const [noOfRecords, setNoOfRecords] = useState(0);
  const limitValue = 10;

  const [list, setList] = useState([]);
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    GetList(pageNum || 1)
  }, []);

  async function GetList(val) {
    setIsLoader(true);
    let obje = {
      limitValue: limitValue,
      pageNumber: val || pageNum,
    }
    axios
      .post(`${apiUrl}/client/getBlockedClient`, obje)
      .then((response) => {
        if (response.data.status === 1) {
          setTimeout(() => {
            // console.log("response?.data?.result?.Block",response?.data?.result?.resBlock.totalCount);
            setNoOfRecords(response?.data?.result?.resBlock?.totalCount || 0)
            setList(response?.data?.result?.resBlock?.list);

            setIsLoader(false);
          }, 500);
        }
      })
      .catch((err) => {
        setIsLoader(false);
        console.log(err);
      });
  }

  const unblockClient = (ele) => {
    let obj = {
      id: ele?._id,
      isBlock: ele?.isBlock,
    };

    Swal.fire({
      text: `Do you  want to unblock ${ele?.clientid?.firstname} ? `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoader(true)
        axios
          .post(`${apiUrl}/client/unBlockClient`, obj, {})
          .then((response) => {
            setTimeout(async () => {
              const client = list.filter((blockedclient) => {
                return blockedclient?._id !== ele?._id;
              });
              if (client?.length === 0) {
                if (pageNum === 1) {
                  setPageNum(pageNum)
                  await GetList(pageNum)
                  setList(client);
                  navigate(`/memberblock/${pageNum}`);
                } else {
                  setPageNum(pageNum - 1)
                  await GetList(pageNum - 1)
                  setList(client);
                  navigate(`/memberblock/${pageNum - 1}`);
                }
              } else {
                setList(client);
              }
              swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
                // button: true,
              });
              setIsLoader(false)
            }, 500);
          })
          .catch((err) => {
            setIsLoader(false)
            console.log(err.response);
          });
      }
    })
  };

  const curPage = (pageNum) => {
    navigate(`/memberblock/${pageNum}`);
    setPageNum(pageNum);
    if (isSearching) {
      setIsLoader(true);
      setTimeout(async () => {
        await searchClick(searchname, pageNum)
        setIsLoader(false);

      }, 500);
    } else {
      GetList(pageNum);
    }
  };

  const [isSearching, setSearching] = useState(false);
  const [searchname, setSearchName] = useState("");

  const handlsearchInputs = (e) => {
    setSearchName(e.target.value);
    if (e.target.value !== "") {
      setSearching(true);
      searchClick(e.target.value);
    } else {
      setSearching(false);
      GetList(pageNum);
      setPageNum(pageNum)
    }
  };

  const searchClick = async (searchData, pageNumber) => {
    const obj = {
      limitValue: limitValue,
      pageNumber: pageNumber || pageNum,
      isBlock: 1
    };

    const response = await axios.post(`${apiUrl}/client/searchblockeclient`, { searchname: searchData, obj }, {})
    if (response.data.status === 1) {
      // console.log("search data",response?.data?.result?.paginatedResults);
      setList(response?.data?.result?.paginatedResults)
      setNoOfRecords(response?.data?.result?.totalCount[0]?.count || 0)
      setSearching(true);
    }
  }

  const clearClick = async (e) => {
    setSearchName("");
    setSearching(false);
    GetList(pageNum);
  };

  return (
    <>
      {/* {isLoader && (
        <div className="loading">
          <div className="mainloader"></div>
        </div>
      )} */}
      <div className="sub-head-title">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-12">
              <h1 className="main_title">Blocked Member</h1>
            </div>
          </div>
          {/* <Link to={'/memberreport/1'}>Client Report</Link> */}
        </div>
      </div>
      <div className="container-fluid">
        <div className="col-md-12 col-sm-12 col-12 p-0">
          {isLoader &&
            <div className="loading">
              <div className="mainloader"></div>
            </div>}
          <div className="trainer-main-box">
            {/* <div className="row">
              <div className="col-xl-3 col-md-4 col-md-6 col-sm-12 col-12 mb-3">
                <div className="input-group position-relative">
                  <input
                    className="input-box search-input"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => handlsearchInputs(e)}
                    name="searchinput"
                    value={searchname}
                    autoComplete="off"
                  />
                  {isSearching ? (
                    <>
                      <button className="search_btn mt-0" onClick={clearClick}>
                        <i className="fa fa-times"></i>
                      </button>
                    </>
                  ) : (
                  <>
                    <button
                      className="search_btn mt-0"
                    onClick={() => searchClick("")}
                    >
                      <i className="fa fa-search"></i>
                    </button>
                  </>
                  )}
                </div>
              </div>
            </div> */}
            <div className="box-card">
              <div className="row">
                <div className="col-md-12 col-12 record_table mt-2">
                  {list?.length > 0 ? (
                    <>
                      <div className="table-responsive">
                        <table className="table table-bordered table-hover mb-0">
                          <thead>
                            <tr>
                              <th className="wd70">Sr. No</th>
                              <th className="wd150 text-center">Trainer profile</th>
                              <th>Trainer Name</th>
                              <th>Reason </th>
                              <th className="text-center wd100">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {list?.length > 0 ? (
                              list?.map((ele, index) => {
                                return (
                                  <tr key={"index" + index}>
                                    <td> {index + 1 + (pageNum - 1) * limitValue}</td>
                                    <td>
                                      <img
                                        src={`${apiUrl + ele?.clientid?.profile
                                          }`}
                                        onError={(e) => {
                                          e.target.src = "/img/Back-No-Image.png";
                                        }} className="pro-img" alt="img" />
                                    </td>
                                    <td className="text-nowrap"
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {`${ele?.clientid?.firstname} ${ele?.clientid?.lastname} `}

                                    </td>

                                    {/* <td>{ele?.sessionworkout?.fitnessGoals}</td> */}
                                    <td>
                                      {ele?.reason ?
                                        (ele.reason.length > 50 ?
                                          <span class="reason-text" title={ele.reason}>
                                            {ele.reason}
                                          </span>
                                          :
                                          ele.reason
                                        ) : "N/A"}

                                    </td>
                                    <td className="text-center">
                                      <div className="d-flex justify-content-center">
                                        <button
                                          className="btn btn-info mr-2"
                                          onClick={() => {
                                            unblockClient(ele);

                                          }}
                                        >
                                          <i
                                            className="fa fa-unlock-alt"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <>
                                <td className="text-center" colSpan="9">
                                  <div className="col-12">
                                    <div className="no-record-box">
                                      <img
                                        src="/img/no-record.png"
                                        alt="no-img"
                                      />
                                      <h4 className="font-weight-bold">
                                        No Member Blocked By You Yet
                                      </h4>
                                    </div>
                                  </div>
                                </td>
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : isLoader === false && (
                    <div className="col-12">
                      <div className="no-record-box">
                        <img src="/img/no-record.png" alt="no-img" />
                        <h4 className="font-weight-bold">Sorry, No Member Block available Yet</h4>
                        <p>Do you want to block a Member ? <Link className="ps-1 linktext" to={"/schedulerequest"}>Click hear</Link>
                        </p>
                      </div>
                    </div>
                  )
                  }

                </div>

              </div>

              <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                <Pagination
                  className="pagination-bar"
                  currentPage={pageNum}
                  totalCount={noOfRecords}
                  pageSize={limitValue}
                  onPageChange={(page) => curPage(page)}
                />

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientBlock;
