import React, { useCallback, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import RequireAuth from "./RequireAuth";
import CancellationPolicy from "./components/CancellationPolicy";
import BookSessionsDetail from "./components/Client/BookSessionsDetail";
import ClientPayment from "./components/Client/ClientPayment";
import FeedBackRating from "./components/Client/FeedBackRating";
import ForgotPasswordClient from "./components/Client/ForgotPassword";
import LoginClient from "./components/Client/Login";
import MySession from "./components/Client/MySession";
import MyWorkout from "./components/Client/MyWorkout";
import PurchaseSession from "./components/Client/PuchaseSession";
import SavedTrainer from "./components/Client/SavedTrainer";
import ClientSignUp from "./components/Client/SignUp";
import Trainer from "./components/Client/Trainer";
import WorkOutForm from "./components/Client/WorkOutForm";
import FAQ from "./components/FAQ";
import TrainerPaymentHistory from "./components/Trainer/TrainerPaymentHistory";
import Calling from "./components/Others/Calling";
import Incoming from "./components/Others/Incoming";
import VideoSession from "./components/Others/VideoSession";
import Videosessionhistory from "./components/Others/vediosessionhistory";
import ClientProfile from "./components/Profile/ClientProfile";
import EditProfile from "./components/Profile/EditProfile";
import MyProfile from "./components/Profile/MyProfile";
import Notifications from "./components/Profile/Notifications";
import TrainerInformation from "./components/Profile/TrainerInformation";
import ViewPhoto from "./components/Profile/ViewPhoto";
import ViewProgresUpload from "./components/Profile/ViewProgresUpload";
import ResetPassword from "./components/ResetPassword";
import SignupSuccess from "./components/SignupSuccess";
import TermsAndCondition from "./components/TermsAndCondition";
import TrainerAccountInfo from "./components/Trainer/AccounntInfo";
import ForgotPasswordTrainer from "./components/Trainer/ForgotPassword";
import LoginTrainer from "./components/Trainer/Login";
import ManageNotifications from "./components/Trainer/ManageNotifications";
import MyRatings from "./components/Trainer/MyRating";
import ScheduleRequest from "./components/Trainer/ScheduleRequest";
import SessionDetails from "./components/Trainer/SessionDetails";
import TrainerSignUp from "./components/Trainer/SignUp";
import TrainerSchedule from "./components/Trainer/TrainerSchedule";
import LandingPage from "./LandingPage";
import "./app.css";
import BlockedTrainners from "./components/Client/BlockedTrainners";
import ClientPaymentHistory from "./components/Client/ClientPaymentHistory";
import SessionPaymentDetail from "./components/Client/SessionPaymentDetail";
import ContactUs from "./components/ContactUs";
import Howitworks from "./components/HowItWorks";
import Allnotifications from "./components/Others/Allnotifications";
import MobileVedioSession from "./components/Others/MobileVedioSession";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ClientBlock from "./components/Trainer/ClientBlock";
// import MyRating from "./components/Trainer/MyRating";
import PendingWorkout from "./components/Trainer/PendingWorkout";
import MainLayout from "./layouts/MainLayout";
import { useSocket } from "./context/SocketProvider";
import Privacy from "./components/Privacy";
import ReportTrainer from "./components/Client/ReportTrainer"
import ClientReport from "./components/Trainer/ClientReport";
import TermsAndConditionApp from "./components/public-view/TermsAndConditionApp";
import PrivacyPolicyApp from "./components/public-view/PrivacyPolicyApp";
import FAQApp from "./components/public-view/FAQApp";
import CancellationPolicyApp from "./components/public-view/CancellationPolicyApp";
import UploadProgressPhotos from "./components/Profile/UploadProgressPhotos";
import TrainerProfile from "./components/Profile/TrainerProfile";
// const socket = io("http://localhost:3155")

function AppRoutes() {

  const [filterPanel, setFilterPanel] = useState("");
  const [serachValue, setSerachValue] = useState("");
  const [login, setLogin] = useState(false);
  const [pathnameUrl] = useState(window.location.pathname);

  const navigate = useNavigate();

  //socket io
  const socket = useSocket();
  const user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    if (user?._id) {
      socket.emit("online", { userid: user._id });
      // console.log("1.client is online", user._id)
    }
  }, [user, socket, pathnameUrl]);



  const allCallAccepted = useCallback(
    (data) => {
      navigate(`/Incoming?mid=${data?.meetingId}`);
    },
    [navigate]
  );

  useEffect(() => {
    socket.on("callAccepted", allCallAccepted);
    // console.log("7.call accepted navigate incoming")

    return () => {
      socket.off("callAccepted", allCallAccepted);
    };
  }, [socket, allCallAccepted]);


  const checkMediaDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const updatedCamera = devices.find(device => device.kind === 'videoinput') ? true : false;
      const updatedMicrophone = devices.find(device => device.kind === 'audioinput') ? true : false;
      const obj = { camera: updatedCamera, microphone: updatedMicrophone }
      sessionStorage.setItem("mediaDevice", JSON.stringify(obj));
    } catch (error) {
      console.error('Error checking media devices:', error);
    }
  };

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      checkMediaDevices();

      const handleDeviceChange = () => {
        checkMediaDevices();
      };
      navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);

      return () => {
        navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange);
      };
    } else {
      console.error('enumerateDevices not supported.');
    }
  }, []);

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/mainlogin" element={<LoginClient />} />
      <Route
        path="/client/login"
        element={<LoginClient login={login} setLogin={setLogin} />}
      />
      <Route path="/trainer/login" element={<LoginTrainer />} />
      <Route path="/clientsignup" element={<ClientSignUp />} />
      <Route path="/trainersignup" element={<TrainerSignUp />} />
      <Route path="/signupsuccess" element={<SignupSuccess />} />
      <Route path="/client/forgotpassword" element={<ForgotPasswordClient />} />
      <Route path="/trainer/forgotpassword" element={<ForgotPasswordTrainer />} />
      <Route
        path="/:usertype?/account/resetpassword/:credentials?"
        element={<ResetPassword />}
      />
      <Route path="/trainerschedule" element={<TrainerSchedule />} />
      <Route path="/trainersaccountinfo" element={<TrainerAccountInfo />} />
      <Route path="/termsandcondition" element={<TermsAndCondition />} />
      <Route path="/mobilevideosession" element={<MobileVedioSession />} />
      <Route path="/vediosessionhistory" element={<Videosessionhistory />} />
      <Route path="/public-view/termsandconditionapp" element={<TermsAndConditionApp />} />
      <Route path="/public-view/privacypolicyapp" element={<PrivacyPolicyApp />} />
      <Route path="/public-view/faqapp" element={<FAQApp />} />
      <Route path="/public-view/cancellationpolicyapp" element={<CancellationPolicyApp />} />
      {/* websites info */}
      <Route path="/howitworks" element={<Howitworks />} />
      <Route path="/Contactus" element={<ContactUs />} />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} />

      {/* require authentication and authorization */}
      <Route element={<RequireAuth login={login} />}>
        <Route
          element={
            <MainLayout
              filterPanel={filterPanel}
              setFilterPanel={setFilterPanel}
              setSerachValue={setSerachValue}
            />
          }
        >
          <Route
            path="/trainer"
            element={<Trainer type={filterPanel} serachValue={serachValue} />}
          />
          <Route
            path="/savedtrainer"
            element={
              <SavedTrainer type={filterPanel} serachValue={serachValue} />
            }
          />
          <Route path="/mysession" element={<MySession />} />
          <Route path="/sessiondetails" element={<SessionDetails />} />
          <Route path="/booksessionsdetail" element={<BookSessionsDetail />} />
          <Route path="/myWorkoutHistory/:page" element={<MyWorkout />} />
          <Route path="/purchasesession" element={<PurchaseSession />} />
          <Route path="/clientpayment" element={<ClientPayment />} />
          <Route
            path="/payment/clientpaymenthistory/:page"
            element={<ClientPaymentHistory />}
          />
          <Route
            path="/SessionPaymentDetail"
            element={<SessionPaymentDetail />}
          />
          <Route path="/blockedtrainers/:page" element={<BlockedTrainners />} />
          <Route path="/reporttrainer/:page" element={<ReportTrainer />} />
          <Route path="/trainerinformation" element={<TrainerInformation />} />
          <Route path="/workoutform" element={<WorkOutForm />} />
          <Route path="/cancellationpolicy" element={<CancellationPolicy />} />
          <Route path="/faq" element={<FAQ />} />

          <Route path="/myprofile" element={<MyProfile />} />
          <Route path="/editprofile" element={<EditProfile />} />

          <Route path="/schedulerequest" element={<ScheduleRequest />} />
          <Route path="/myratings" element={<MyRatings />} />
          <Route path="/paymenthistory/:page" element={<TrainerPaymentHistory />} />
          <Route path="/pendingworkout/:page" element={<PendingWorkout />} />
          <Route path="/termsncondition" element={<TermsAndCondition />} />
          <Route path="/clientprofile" element={<ClientProfile />} />
          <Route path="/trainerprofile" element={<TrainerProfile />} />
          <Route path="/ViewPhoto" element={<ViewPhoto />} />
          {/* <Route path="/ViewProgresUpload" element={<ViewProgresUpload />} /> */}
          <Route path="/UploadProgressPhotos" element={<UploadProgressPhotos />} />
          <Route
            path="/manageNotifications"
            element={<ManageNotifications />}
          />
          <Route path="/sessiondetails" element={<SessionDetails />} />
          <Route path="/Incoming" element={<Incoming />} />
          <Route path="/calling" element={<Calling />} />
          <Route path="/videosession/:meetingId" element={<VideoSession />} />
          {/* <Route path="/myratings" element={<MyRating />} /> */}
          <Route path="/rating" element={<FeedBackRating />} />
          <Route path="/memberblock/:page" element={<ClientBlock />} />
          <Route path="/memberreport/:page" element={<ClientReport />} />
          <Route path="/privacy" element={<Privacy />} />

          <Route path="/notifications" element={<Notifications />} />
          <Route path="/allnotification" element={<Allnotifications />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default AppRoutes;
