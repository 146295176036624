import axios from "axios";
import { apiUrl } from "../../environment/environment";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Pagination from "../../Pagination/Pagination";

// 1 -Block & 2 -Report

function BlockedTrainners() {
  const navigate = useNavigate();
  const { page } = useParams()

  const limitValue = 10;
  const [pageNum, setPageNum] = useState(+page);
  const [noOfRecords, setNoOfRecords] = useState(0);

  const [block, setBlock] = useState([]);
  const [isLoader, setIsLoader] = useState(false);



  useEffect(() => {
    GetList(pageNum || 1)
  }, []);

  async function GetList(val) {
    let obje = {
      limitValue: limitValue,
      pageNumber: val || pageNum,
    }
    setIsLoader(true);
    axios
      .post(`${apiUrl}/client/getblockedtrainners`, obje, {})
      .then((response) => {
        if (response.data.status === 1) {
          setTimeout(() => {
            if (response?.data?.result.length === 0) {
              setSearchData(false)
            }
            setBlock(response?.data?.result?.resBlock?.list);
            setNoOfRecords(response?.data?.result?.resBlock?.totalCount || 0)
            setIsLoader(false);
          }, 500);
        }
      })
      .catch((err) => {
        setIsLoader(false);
        console.log(err);
      });
  }

  const unblockTrainers = (ele) => {
    let obj = {
      id: ele?._id,
      isBlock: ele?.isBlock
    }
    // console.log("obj",obj);
    Swal.fire({
      text: `Do you  want to unblock ${ele?.trainerid?.firstname} ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoader(true)
        axios
          .post(`${apiUrl}/client/unblockTrainers`, obj, {})
          .then((res) => {
            setTimeout(async () => {
              const trainers = block.filter((BlockedTrainers) => {
                return BlockedTrainers._id !== ele?._id;
              });
              if (trainers.length === 0) {
                if (pageNum === 1) {
                  setPageNum(pageNum)
                  await GetList(pageNum)
                  setBlock(trainers);
                  navigate(`/blockedtrainers/${pageNum}`);
                } else {
                  setPageNum(pageNum - 1)
                  await GetList(pageNum - 1)
                  setBlock(trainers);
                  navigate(`/blockedtrainers/${pageNum - 1}`);
                }
              } else {
                setBlock(trainers);
              }
              setIsLoader(false)
              swal({
                title: "Success!",
                text: res.data.message,
                icon: "success",
                button: "ok",
              });
            }, 500);

          })
          .catch((err) => {
            setIsLoader(false)
            window.alert(err);
            console.log(err);
          });
      }
    });

  };

  const curPage = (pageNum) => {
    navigate(`/blockedtrainers/${pageNum}`);
    setPageNum(pageNum);
    if (isSearching) {
      setIsLoader(true);
      setTimeout(async () => {
        await searchClick(searchname, pageNum)
        setIsLoader(false);

      }, 500);
    } else {
      GetList(pageNum);
    }
  };

  const [isSearching, setSearching] = useState(false);
  const [searchname, setSearchName] = useState("");
  const [searchData, setSearchData] = useState(false)

  const handlsearchInputs = (e) => {
    setSearchName(e.target.value);
    if (e.target.value !== "") {
      setSearchData(true);
      searchClick(e.target.value);
    } else {
      setSearchData(false);
      setSearching(false);
      GetList(pageNum);
      setPageNum(pageNum)
    }
  };

  const searchClick = async (searchData, pageNumber) => {
    const obj = {
      limitValue: limitValue,
      pageNumber: pageNumber || pageNum,
      isBlock: 1
    };

    const response = await axios.post(`${apiUrl}/client/searchBlockeTrainer`, { searchname: searchData, obj }, {})
    if (response.data.status === 1) {
      setBlock(response?.data?.result?.paginatedResults)
      setNoOfRecords(response?.data?.result?.totalCount[0]?.count || 0)
      setSearching(true);
    }
  }

  const clearClick = async (e) => {
    setSearchName("");
    setSearching(false);
    GetList(pageNum);
  };

  return (
    <>

      <div className="sub-head-title">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-12">
              <h1 className="main_title">Blocked Trainers</h1>
            </div>
          </div>

        </div>
      </div>
      <div className="container-fluid">
        <div className="col-md-12 col-sm-12 col-12 p-0">
          <div className="trainer-main-box">

            {isLoader &&
              <div id="loader" className="loading">
                <div className="mainloader"></div>
              </div>
            }
            <div className="box-card">
              <div className="row">

                {/* <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4">
                  <div className="input-group position-relative">
                    <input
                      className="input-box search-input"
                      type="text"
                      placeholder="Search"
                      onChange={(e) => handlsearchInputs(e)}
                      name="searchinput"
                      value={searchname}
                      autoComplete="off"
                    />
                    {isSearching ? (
                      <>
                        <button className="search_btn mt-0" onClick={clearClick}>
                          <i className="fa fa-times"></i>
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="search_btn mt-0"
                          onClick={() => searchClick("")}
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      </>
                    )}
                  </div>
                </div> */}

                <div className="col-md-12 col-12 record_table mt-2">
                  {/* <div className="table-responsive">

                    <table className="table table-bordered table-hover mb-0">
                      {(block?.length > 0 || (searchData === true)) && (<><thead>
                        <tr>
                          <th className="wd70">Sr. No</th>
                          <th className="wd150 text-center">Profile</th>
                          <th>Trainer Name</th>
                          <th>Reason</th>
                          <th className="text-center wd100">Action</th>
                        </tr>
                      </thead></>)}
                      <tbody>
                        {block?.length > 0 ?
                          block.map((ele, index) => {
                            return (
                              <tr key={"index" + index}>
                                <td> {index + 1 + (pageNum - 1) * limitValue}</td>
                                <td className="text-center">
                                  <img
                                    src={`${apiUrl + ele.trainerid.profile
                                      }`}
                                    onError={(e) => {
                                      e.target.src = "/img/Back-No-Image.png";
                                    }}
                                    className="pro-img" alt=""
                                  />
                                </td>
                                <td className="text-nowrap"
                                  style={{
                                    textTransform: "capitalize",
                                  }}>
                                  {`${ele?.trainerid?.firstname} ${ele?.trainerid?.lastname}`}
                                </td>
                                <td >

                                  {ele?.reason ?
                                    (ele.reason.length > 50 ?
                                      <span class="reason-text" title={ele.reason}>
                                        {ele.reason}
                                      </span>
                                      :
                                      ele.reason
                                    ) : "N/A"}

                                </td>

                                <td className="text-center">
                                  <div className="d-flex justify-content-center">
                                    <button
                                      className="btn btn-info mr-2"
                                      onClick={() => {
                                        unblockTrainers(ele);
                                      }}
                                    >
                                      <i
                                        className="fa fa-unlock-alt"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          }) :
                          <>
                            {!isLoader && (
                              <div className="col-12">
                                <div className="text-center">
                                  <div className="no-record-box">
                                    <img
                                      src="/img/no-record.png"
                                      alt="no-img"
                                    />
                                    <h4 className="font-weight-bold">
                                      No trainer Found
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>}
                      </tbody>

                    </table>
                    {/* </>) : (<>
                      {isLoader === false && (
                        <div className="text-center" colspan="5">
                          <div className="col-12">
                            <div className="no-record-box">
                              <img src="/img/no-record.png" alt="no-img" />
                              <h4 className="font-weight-bold">
                                No trainer Found
                              </h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </>)} 
                  </div> */}
                  <div className="table-responsive">
                    {block?.length > 0 ? (
                      <table className="table table-bordered table-hover mb-0">
                        <thead>
                          <tr>
                            <th className="wd70">Sr. No</th>
                            <th className="wd150 text-center">Trainer profile</th>
                            <th className="">Trainer Name</th>
                            <th>Reason </th>
                            <th className="text-center wd100">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <>
                            {block?.length > 0 ? (
                              block?.map((ele, index) => {
                                console.log("ele", ele);
                                return (
                                  <tr key={"index" + index}>
                                    <td> {index + 1 + (pageNum - 1) * limitValue}</td>
                                    <td className="text-center">
                                      <img
                                        src={`${apiUrl + ele?.trainerid?.profile
                                          }`}
                                        onError={(e) => {
                                          e.target.src =
                                            "/img/Back-No-Image.png";
                                        }} className="pro-img" alt="img" />
                                    </td>
                                    <td className="text-nowrap"
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {`${ele?.trainerid?.firstname} ${ele?.trainerid?.lastname}`}
                                    </td>

                                    <td>
                                      {ele?.reason ?
                                        (ele.reason.length > 50 ?
                                          <span class="reason-text" title={ele.reason}>
                                            {ele.reason}
                                          </span>
                                          :
                                          ele.reason
                                        ) : "N/A"}
                                    </td>
                                    <td className="text-center">
                                      <div className="d-flex justify-content-center">
                                        <button
                                          className="btn btn-info mr-2"
                                          onClick={() => {
                                            unblockTrainers(ele);
                                          }}
                                        >
                                          <i
                                            className="fa fa-unlock-alt"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <>
                                {!isLoader && (
                                  <div className="col-12">
                                    <div className="text-center">
                                      <div className="no-record-box">
                                        <img
                                          src="/img/no-record.png"
                                          alt="no-img"
                                        />
                                        <h4 className="font-weight-bold">
                                          No trainer Found
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        </tbody>
                      </table>
                    ) : (
                      <>
                        {isLoader === false && (
                          <div className="text-center" colspan="5">
                            <div className="col-12">
                              <div className="no-record-box">
                                <img src="/img/no-record.png" alt="no-img" />
                                <h4 className="font-weight-bold">Sorry, No Trainers Block available Yet</h4>
                                <p>Do you want to block a trainer ? <Link className="ps-1 linktext" to={"/trainer?status=0"}>Click hear</Link>
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                    <Pagination
                      className="pagination-bar"
                      currentPage={pageNum}
                      totalCount={noOfRecords}
                      pageSize={limitValue}
                      onPageChange={(page) => curPage(page)}
                    />

                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>

    </>
  );
}

export default BlockedTrainners;
