import axios from "axios";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { apiUrl } from "../../environment/environment";
import Moment from "react-moment";
import Pagination from "../../Pagination/Pagination";
import { useNavigate } from "react-router-dom";
const ShowAvailableSessions = () => {
    const [sessionList, setSessionList] = useState();
    const [isLoader, setIsLoader] = useState(false);

    const limitValue = 10;
    const [pageNum, setPageNum] = useState(1);
    const [noOfRecords, setNoOfRecords] = useState(0);

    useEffect(() => {
        getAvailableSessions();
    }, []);

    const getAvailableSessions = async (pageNum) => {
        let obj = {
            limitValue: limitValue,
            pageNumber: pageNum,
        }
        setIsLoader(true)
        const response = await axios.post(`${apiUrl}/payment/clientpaymenthistory`, obj)
        if (response) {
            setTimeout(() => {
                setSessionList(response?.data?.result?.paymentlist);
                setNoOfRecords(response?.data?.result?.noOfRecords)
                setIsLoader(false)
            }, 500);
        }
    }

    const curPage = (pageNumCur) => {
        setPageNum(pageNumCur);
        getAvailableSessions(pageNumCur);
    };

    return (
        <>
            {isLoader &&
                <div className="loading">
                    <div className="mainloader"></div>
                </div>
            }
            <Modal.Header className="session-m" closeButton>
                <Modal.Title className="book-title d-flex align-items-center ml-3 mb-0">
                    <img src="/img/common-health.png" alt="" />
                    <h4 className="mb-0 ml-3 font-weight-bold">Available Sessions</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-2">

                <div className="col-md-12 col-12">
                    <div className="history-table table-responsive payment-histroy d-flex justify-content-center">
                        {sessionList?.length > 0 ? (<>
                            <table className="table" id="client_payment_history">
                                <thead>
                                    <tr>
                                        <th className="wd70">Sr. No</th>
                                        <th>Plan</th>
                                        <th>Purchase Date</th>
                                        <th>No of session(s)</th>
                                        <th>Available session(s)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sessionList?.length > 0 ? (

                                        sessionList?.map((element, index) => {
                                            return (
                                                <>
                                                    <tr key={`payment_${element}`}>
                                                        <td>{index + 1 + (1 - 1) * 10}</td>
                                                        <td>{element.plantype}</td>
                                                        <td><Moment format="DD MMMM YYYY, hh:mm A" date={element.createdAt} /></td>
                                                        <td>
                                                            {element.noofsession === 1
                                                                ? "Single session"
                                                                : element.noofsession + " sessions"}
                                                        </td>
                                                        <td>
                                                            {(element?.availablesessions < 1)
                                                                ? "Completed session"
                                                                : element.availablesessions + " sessions"
                                                            }
                                                        </td>
                                                    </tr>
                                                </>
                                            );
                                        })
                                    ) : (
                                        <>
                                            <tr>
                                                <td colSpan="10" className="-text-center">
                                                    <div className="col-12 p-0 mb-4">
                                                        <div className="trainer-main-box pb-0">
                                                            <div className="trainer-main-box">
                                                                <div className="no-record-box">
                                                                    <img
                                                                        src="/img/no-record.png"
                                                                        alt="no-img"
                                                                    />
                                                                    <h4 className="font-weight-bold">
                                                                        Available Sessions Empty !
                                                                    </h4>
                                                                    <p>
                                                                        You have no Available sessions yet
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    )}

                                </tbody>
                            </table>
                        </>)
                            : (<>
                                {!isLoader && <tr>
                                    <td colSpan="10" className="-text-center">
                                        <div className="col-12 p-0 mb-4">
                                            <div className="trainer-main-box pb-0">
                                                <div className="trainer-main-box">
                                                    <div className="no-record-box">
                                                        <img
                                                            src="/img/no-record.png"
                                                            alt="no-img"
                                                        />
                                                        {/* <h4 className="font-weight-bold">
                                                           No Available Sessions!
                                                        </h4> */}
                                                        <p>
                                                            You have no Available sessions
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>}
                            </>)}
                    </div>
                    <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                        <Pagination
                            className="pagination-bar"
                            currentPage={pageNum}
                            totalCount={noOfRecords}
                            pageSize={limitValue}
                            onPageChange={(page) => curPage(page)}
                        />
                    </div>
                </div>
            </Modal.Body>
        </>
    )
}

export default ShowAvailableSessions